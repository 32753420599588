import {useEffect, useState} from "react";

const useWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', event => {
            setWidth(event.target.innerWidth);
        });
    }, [window.innerWidth]);

    return width;
}

export default useWidth;
