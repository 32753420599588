import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {articles} from "./articles.data";

const getArticles = createAsyncThunk("articles/getArticles", async ({}) => {
    try {

    }catch (e) {

    }
});

const getArticle = createAsyncThunk("articles/getArticle", async ({}) => {
    try {

    }catch (e) {

    }
});


const articlesSlice = createSlice({
    name: "articles",
    initialState: {
        articleLoading: false,
        articleError: null,
        articles: [...articles],
        article: null,
        page: 2,
        count: 30,
        limit: 20
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getArticles.pending, (state) => {
            state.articleLoading = true;
            state.articleError = null;
        }).addCase(getArticles.fulfilled, (state, action) => {
            state.articles = action.payload;
            state.articleLoading = false;
            state.articleError = null;
        }).addCase(getArticles.rejected, (state, action) => {
            state.articles = [];
            state.articleLoading = false;
            state.articleError = action.payload;
        }).addCase(getArticle.pending, (state) => {
            state.articleLoading = true;
            state.articleError = null;
        }).addCase(getArticle.fulfilled, (state, action) => {
            state.article = action.payload;
            state.articleLoading = false;
            state.articleError = null;
        }).addCase(getArticle.rejected, (state, action) => {
            state.article = null;
            state.articleLoading = false;
            state.articleError = action.payload;
        })
    }
});


const {reducer} = articlesSlice;

export const selectArticles = state => state.articles;

export default reducer;
