import {Box, Container, Divider, Grid, Link, Stack, Typography} from "@mui/material";
import NavbarLink from "../shared/navbar-link";
import {useLocation} from "react-router-dom";

const DesktopFooter = () => {

    const {pathname} = useLocation();

    return (
        <Box sx={{backgroundColor: 'background.paper', paddingY: 4}}>
            <Container maxWidth="xl">
                <Grid container={true} spacing={3}>
                    <Grid item={true} xs={3}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            Main
                        </Typography>

                        <Divider variant="fullWidth" sx={{my: 2}} light={true}/>

                        <Stack spacing={2}>
                            <NavbarLink path="/" active={pathname === '/'} label="Home"/>
                            <NavbarLink path="/about" active={pathname === '/about'} label="About Us"/>
                            <NavbarLink path="/contact" active={pathname === '/contact'} label="Contact Us"/>
                            <NavbarLink path="/leadership" active={pathname === '/leadership'} label="Leadership"/>
                            <NavbarLink path="/alumni" active={pathname === '/alumni'} label="Alumni"/>
                            <NavbarLink path="/calendar" active={pathname === '/calendar'} label="Calendar"/>
                        </Stack>
                    </Grid>
                    <Grid item={true} xs={3}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            Departments
                        </Typography>

                        <Divider variant="fullWidth" sx={{my: 2}} light={true}/>

                        <Stack spacing={2}>
                            <NavbarLink
                                path="/departments"
                                active={pathname === '/departments'}
                                label="Overview"
                            />
                            <NavbarLink
                                path="/departments/preschool"
                                active={pathname === '/departments/preschool'}
                                label="Preschool"
                            />
                            <NavbarLink
                                path="/departments/primary"
                                active={pathname === '/departments/primary'}
                                label="Primary"
                            />
                            <NavbarLink
                                path="/departments/jhs"
                                active={pathname === '/departments/jhs'}
                                label="JHS"
                            />
                            <NavbarLink
                                path="/departments/international"
                                active={pathname === '/departments/international'}
                                label="International Secondary"
                            />
                        </Stack>
                    </Grid>
                    <Grid item={true} xs={3}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            News
                        </Typography>

                        <Divider variant="fullWidth" sx={{my: 2}} light={true}/>

                        <Stack spacing={2}>
                            <NavbarLink
                                path="/news"
                                active={pathname === '/news'}
                                label="Overview"
                            />
                            <NavbarLink
                                path="/news/preschool"
                                active={pathname === '/news/preschool'}
                                label="Facebook"
                            />
                            <NavbarLink
                                path="/news/primary"
                                active={pathname === '/news/instagram'}
                                label="Instagram"
                            />
                            <NavbarLink
                                path="/news/jhs"
                                active={pathname === '/news/twitter'}
                                label="Twitter"
                            />
                            <NavbarLink
                                path="/news/articles"
                                active={pathname === '/news/articles'}
                                label="Articles"
                            />
                        </Stack>
                    </Grid>
                    <Grid item={true} xs={3}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            Portals
                        </Typography>

                        <Divider variant="fullWidth" sx={{my: 2}} light={true}/>

                        <Stack spacing={3}>
                            <Link
                                sx={{color: "text.secondary", fontWeight: 500}}
                                href="https://student.mothercareschool.edu.gh"
                                target="_blank"
                                underline="hover">
                                Student Portal
                            </Link>
                            <Link
                                sx={{color: "text.secondary", fontWeight: 500}}
                                href="https://parent.mothercareschool.edu.gh"
                                target="_blank"
                                underline="hover">
                                Parent Portal
                            </Link>
                            <Link
                                sx={{color: "text.secondary", fontWeight: 500}}
                                href="https://staff.mothercareschool.edu.gh"
                                target="_blank"
                                underline="hover">
                                Staff Portal
                            </Link>
                            <Link
                                sx={{color: "text.secondary", fontWeight: 500}}
                                href="https://alumni.mothercareschool.edu.gh"
                                target="_blank"
                                underline="hover">
                                Alumni Portal
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default DesktopFooter;
