import {Stack, Typography} from "@mui/material";
import {CheckCircleOutline} from "@mui/icons-material";

const SubjectText = ({subject, color, backgroundColor, iconColor}) => {
    return (
        <Stack sx={{backgroundColor, borderRadius: 2, padding: 2}} spacing={3} direction="row" alignItems="center">
            <CheckCircleOutline sx={{color: iconColor}}/>
            <Typography variant="h6" sx={{fontWeight: 700, color}}>{subject}</Typography>
        </Stack>
    )
}

export default SubjectText;
