import Layout from "../../components/layout/layout";
import banner from "../../assets/images/preschool-banner.jpg";
import story1 from "../../assets/images/story1-preschool.jpg";
import {Box, Button, CardMedia, Container, Grid, Link as MUILink, Typography} from "@mui/material";
import {MOTHERCARE_SCHOOL_DATA} from "../../utils/data";
import Feature from "../../components/shared/feature";
import Step from "../../components/shared/step";
import Subject from "../../components/shared/subject";
import admissionForm from "./../../assets/docs/MCEnrolmentForm2022.pdf";
import ImageGallery from "react-image-gallery";

const PreschoolPage = () => {

    return (
        <Layout>
            <Box
                sx={{
                    position: 'relative',
                    pt: 12.5,
                    minHeight: {xs: '50vh', lg: '100vh'},
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('${banner}')`,
                    backgroundAttachment: 'scroll',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingY: {xs: 4, lg: 0},
                    backgroundBlendMode: 'multiply',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}>
                <Container>
                    <Typography align="center" variant="h3" sx={{color: 'white', mb: 4}}>
                        Pre-school
                    </Typography>
                    <Typography align="center" variant="h6" sx={{color: 'white', mb: 4}}>
                        Developing little minds
                    </Typography>
                </Container>
            </Box>


            <Box sx={{py: 3, backgroundColor: "secondary.main"}}>
                <Container>
                    <Grid
                        container={true}
                        justifyContent="space-between"
                        spacing={2}
                        alignItems="center">
                        <Grid item={true} xs={12} md="auto">
                            <Typography variant="h5" sx={{color: "white"}}>
                                Admissions open for 2023
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} md="auto">
                            <MUILink href="#enrol" sx={{textDecoration: 'none'}}>
                                <Button
                                    sx={{
                                        textTransform: 'capitalize'
                                    }}
                                    disableElevation={true}
                                    variant="contained"
                                    color="primary"
                                    size="large">
                                    Enroll Now
                                </Button>
                            </MUILink>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container>
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                Raising Ethical Global Leaders
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.primary', mb: 2}}>
                                Mothercare Pre-School, which was established in 1965, operated as a nursery school only
                                for almost 30 years before it was expanded to include the rest of the school. It was one
                                of the early adopters of the Jolly Phonics model for teaching literacy in Ghana and
                                continues to lead in its operation. Products of Mothercare Pre-School have keen reading
                                habits and the ability to express themselves very well.
                            </Typography>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={story1}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                        Key Features
                    </Typography>
                    <Grid container={true} spacing={2}>
                        {MOTHERCARE_SCHOOL_DATA.KEY_FEATURES.map((feature, index) => {
                            return (
                                <Grid item={true} key={index} xs={12} md={4}>
                                    <Feature feature={feature}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>

            <Box
                id="steps"
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        Enrolment Steps
                    </Typography>

                    <Typography
                        variant="body2"
                        sx={{color: 'text.primary', mb: 4}}>
                        Our pre-school caters for children from 6 months to 6 years old. Children may be registered for
                        our pre-school anytime from birth, either for enrolment into our Creche at 6 months, Nursery 1
                        (2 years), Nursery 2 (3 years), Kindergarten 1 (4 years) and Kindergarten 2 (5 years). Please
                        note that our Pre-School has limited space and parents are encouraged to apply early to avoid
                        disappointment. To enroll your child, you must:
                    </Typography>

                    <Grid sx={{mb: 4}} container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="01">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Download Application Form
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Download{' '}
                                    <MUILink
                                        sx={{color: "secondary.main"}}
                                        href={admissionForm}
                                        download="MCEnrolmentForm2022.docx">
                                        Application Form
                                    </MUILink>{' '}
                                    / collect from the
                                    <MUILink
                                        sx={{color: "secondary.main"}}
                                        href={"https://goo.gl/maps/3FszttUjMoarMoiu8"}>
                                        Administrative office
                                    </MUILink>.
                                </Typography>

                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="02">
                                <Typography variant="h6" sx={{color: "text.primary", mb: 1}}>
                                    Attend Readiness Assessment Interview
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 1}}>
                                    This includes a Kindergarten entrance examination (Literacy and Numeracy) and an
                                    interview with one of the head teachers.
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 1}}>
                                    (a) Note that there is a small fee covering the entrance examination.
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 1}}>
                                    (b) Examinations may be taken on various dates between May and September on the
                                    school premises.
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 1}}>
                                    (c) Students will only be granted admission if there are vacancies in the class they
                                    require.
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="02">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    <span style={{fontWeight: "bold"}}>Submit Application Form</span> upon approval of
                                    Head teachers, along with the following :
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    1. Student’s birth certificate
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    2. Student’s weighing card, indicating proof of health shots.
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    3. Two passport sized pictures
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    4. One-time items requested for on list provided with admission materials.
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="04">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    <span style={{fontWeight: "bold"}}>Receive Offer of Admission</span> after full
                                    payment of fees is complete.
                                </Typography>
                            </Step>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                        Academic Subjects (Year 1 - 6)
                    </Typography>
                    <Grid container={true} spacing={2}>
                        {MOTHERCARE_SCHOOL_DATA.PRESCHOOL_SUBJECTS.map((subject, index) => {
                            return (
                                <Grid item={true} key={index} xs={12} md={4}>
                                    <Subject subject={subject}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container>
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                Co-Curricular Activities
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.primary', mb: 2}}>
                                At Mothercare School, we believe in providing high quality, outstanding value education
                                in a Christian and challenging learning environment. This is supported in our motto
                                (Proverbs 22 verse 6) which says “Train up a child in the way he should go: and when he
                                is old, he will not depart from it.”
                            </Typography>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={banner}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box sx={{py: 8}}>
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main", mb: 4}}>
                        Gallery
                    </Typography>
                </Container>
                <ImageGallery
                    showPlayButton={false}
                    autoPlay={true}
                    slideDuration={300}
                    showThumbnails={false}
                    items={MOTHERCARE_SCHOOL_DATA.GALLERY.PRESCHOOL}
                />
            </Box>
        </Layout>
    )
}

export default PreschoolPage;
