import {Box, SwipeableDrawer} from "@mui/material";
import Header from "../header/header";
import Footer from "../footer/footer";
import {useDispatch, useSelector} from "react-redux";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import Drawer from "../drawer/drawer";
import {motion} from "framer-motion";

const container = {
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            delay: 0.3,
            duration: 0.5,
        }
    },
    initial: {
        opacity: 0,
        transition: {
            delay: 0.3,
            duration: 0.5,
        }
    },
    exit: {
        opacity: 0,
        transition: {
            delay: 0.3,
            duration: 0.5,
        }
    }

}
const Layout = ({children}) => {

    const {drawerOpen} = useSelector(selectUI);
    const dispatch = useDispatch();

    return (
        <Box
            sx={{
                minHeight: '100vh',
                maxWidth: '100vw',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background.default'
            }}>
            <Box>
                <Header/>
            </Box>
            <Box
                variants={container}
                animate="animate"
                initial="initial"
                exit="exit"
                component={motion.div} sx={{flexGrow: 1, mt: {xs: 9, lg: 13}}}>
                {children}
            </Box>
            <Box>
                <Footer/>
            </Box>

            <SwipeableDrawer
                open={drawerOpen}
                onClose={() => dispatch(UI_ACTION_CREATORS.toggleDrawer(false))}
                onOpen={() => dispatch(UI_ACTION_CREATORS.toggleDrawer(true))}>
                <Drawer/>
            </SwipeableDrawer>
        </Box>
    )
}

export default Layout;
