import {Card, CardContent, Stack, Typography} from "@mui/material";
import {CheckOutlined} from "@mui/icons-material";

const Benefit = ({benefit}) => {
    return (
        <Card
            variant="elevation"
            sx={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 32,
                borderTopLeftRadius: 32,
                borderTopRightRadius: 0,
                height: "100%",
                backgroundColor: "background.paper"
            }} elevation={0}>
            <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                    <CheckOutlined
                        color="secondary"
                        sx={{
                            backgroundColor: "light.secondary",
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 32,
                            borderTopLeftRadius: 32,
                            borderTopRightRadius: 0,
                            padding: 0.8,
                            fontSize: 32
                        }}/>
                    <Typography
                        variant="body2"
                        sx={{
                            color: "text.primary",
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 32,
                            borderTopLeftRadius: 32,
                            borderTopRightRadius: 0,
                        }}>
                        {benefit}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Benefit;
