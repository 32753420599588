import sheila from "../assets/images/1. Sheila Acquah-Asare - School Director .jpg";
import valentia from "../assets/images/2. Vincentia Asase Fayorsey Principal (International School).jpg";
import peace from "../assets/images/4. Peace Abasa-Addo - Head of Pre-school Department.jpg";
import phillipa from "../assets/images/1. Mrs. Philippa Aggrey-Mensah - Board Chairperson.jpg";
import danny from "../assets/images/2. Mr. Danny Main - Vice Board Chairman .jpg";
import patience from "../assets/images/3. Mrs. Patience Damptey - Board Member .jpg";
import ophelia from "../assets/images/4. Mrs. Ophelia Ablorh - Board Member .jpg";
import benson from "../assets/images/5. Mr. Benson Blege - Board Member .jpg"
import lydia from "../assets/images/6. Mrs. Lynda Folson - Board Member .jpg"
import offeibia from "../assets/images/Offeibea agyei ketemfi .jpg";
import christian from "../assets/images/Christian Owusu.jpg";
import solomon from "../assets/images/Solomon Adonoo.jpeg";
import victoria from "../assets/images/Victoria Adu Somuah.jpeg";
import samuel from "../assets/images/Samuel Ankoanna Gyimah.jpeg";
import quarshie from "../assets/images/JOHN QUARSHIE - HEAD OF JUNIOR HIGH DEPARTMENT .jpeg";
import seth from "../assets/images/3. Seth boakye asirifi Head of Junior High Department .jpg";

import spaciousClassroom from "./../assets/images/spacious-classroom.png";
import montessoriEquipment from "./../assets/images/montessori-equipment.webp";
import playgroundEquipment from "./../assets/images/playground-equipment.webp";
import healthySchoolMeals from "./../assets/images/health-school-meals.webp";
import security from "./../assets/images/24-hour-security.png";

import music from "./../assets/images/icons/music.png";
import creativeArts from "./../assets/images/icons/color-palette.png";
import numeracy from "./../assets/images/icons/whiteboard.png";
import literacy from "./../assets/images/icons/english.png";
import science from "./../assets/images/icons/science.png";
import running from "./../assets/images/icons/running.png";

import gallery from "./../assets/images/gallery/IMG_6256.JPG";
import gallery1 from "./../assets/images/gallery/IMG_6385.JPG";
import gallery2 from "./../assets/images/gallery/IMG_6816.jpg";
import gallery3 from "./../assets/images/gallery/IMG_6994.jpg";
import gallery4 from "./../assets/images/gallery/IMG_7037.jpg";
import gallery5 from "./../assets/images/gallery/PHOTO-2022-02-01-14-43-28.jpg";
import gallery6 from "./../assets/images/gallery/PHOTO-2022-06-24-18-05-24 2.jpg";
import gallery7 from "./../assets/images/gallery/PHOTO-2022-07-13-09-48-59 17.jpg";
import gallery8 from "./../assets/images/gallery/PHOTO-2022-07-13-09-52-18 2.jpg";
import gallery9 from "./../assets/images/gallery/PHOTO-2022-07-13-09-52-21 3.jpg";
import gallery10 from "./../assets/images/gallery/PHOTO-2022-06-24-18-05-25 2.jpg";
import gallery11 from "./../assets/images/gallery/PHOTO-2022-06-24-18-05-25 2.jpg";

import headGirlsPrefect from "./../assets/images/src/Britney Aframah Norman - Head Girls Prefect .jpg";
import assistantHeadGirls from "./../assets/images/src/Ethel Tay - Assistant Girls Prefect.jpg";
import assistantHeadBoys from "./../assets/images/src/Emmanuel Segbaya - Assistant Boys Prefect .jpg";
import headBoysPrefect from "./../assets/images/src/Jedidiah Agyefi-Mensah - Head Boys Prefect.jpg";

const LEADERSHIP = {
    BOARD_OF_GOVERNORS: [
        {
            image: phillipa,
            name: 'Mrs. Phillipa  Aggrey - Mensah',
            occupation: 'Educationist',
            position: 'Board Chairperson',
        },
        {
            image: danny,
            name: 'Mr. Danny Mainoo',
            occupation: 'Business Executive',
            position: 'Vice Board Chairman',
        },
        {
            image: sheila,
            name: 'Ms. Sheila Acquah – Asare',
            occupation: 'Executive Director',
            position: 'Board Secretary',
        },
        {
            image: benson,
            name: 'Mr. Benson Blege',
            occupation: 'Chartered Accountant',
            position: 'Board Member',
        },
        {
            image: patience,
            name: 'Mrs. Patience Damptey',
            occupation: 'Environmental and Gender Consultant',
            position: 'Board Member',
        },
        {
            image: ophelia,
            name: 'Ms. Ophelia Ablorh',
            occupation: 'Human Resource Consultant',
            position: 'Board Member',
        },
        {
            image: lydia,
            name: 'Mrs. Lynda Folson',
            occupation: 'Marketing Executive',
            position: 'Board Member',
        },
    ],
    MANAGEMENT_AND_FACULTY: [
        {
            image: sheila,
            name: 'Ms. Sheila Acquah-Asare',
            position: 'Director',
        },
        {
            image: valentia,
            name: 'Mrs. Vincentia Asase-Fayorsey',
            position: 'Principal (International Secondary)',
        },
        {
            image: seth,
            name: 'Mr. Seth Boakye Asirifi',
            position: 'Principal (Senior School)',
        },
        {
            image: peace,
            name: 'Ms. Peace Abasa-Addo',
            position: 'Principal (Junior School)',
        },
        {
            image: offeibia,
            name: 'Mrs. Offeibea Agyei Ketemefi',
            position: 'Administrator (Main School)',
        },
        {
            image: samuel,
            name: 'Mr. Samuel Ankoanna Gyimah',
            position: 'Financial Controller',
        },
        {
            image: christian,
            name: 'Mr. Christian K. Owusu',
            position: 'Property Manager',
        },
        {
            image: solomon,
            name: 'Mr. Solomon Adonoo',
            position: 'Upper Primary Department Head',
        },
        {
            image: victoria,
            name: 'Mrs. Victoria Adu Somuah',
            position: 'Junior Primary Department Head',
        },
        {
            image: quarshie,
            name: 'Mr. John Quarshie',
            position: 'Junior High School Department Head',
        }
    ],
    SRC: [
        {
            image: headGirlsPrefect,
            name: 'Britney Aframah Norman',
            position: 'Head Girls Prefect',
        },
        {
            image: headBoysPrefect,
            name: 'Jedidiah Agyefi-Mensah',
            position: 'Head Boys Prefect',
        },
        {
            image: assistantHeadBoys,
            name: 'Emmanuel Segbaya',
            position: 'Assistant Boys Prefect',
        },
        {
            image: assistantHeadGirls,
            name: 'Ethel Tay',
            position: 'Assistant Girls Prefect',
        }
    ]
}

const GALLERY = {
    HOME: [
        {
            original: gallery,
            thumbnail: gallery
        },
        {
            original: gallery1,
            thumbnail: gallery1
        },
        {
            original: gallery2,
            thumbnail: gallery2
        },
        {
            original: gallery3,
            thumbnail: gallery3
        },
        {
            original: gallery4,
            thumbnail: gallery4
        },
        {
            original: gallery5,
            thumbnail: gallery5
        },
        {
            original: gallery6,
            thumbnail: gallery6
        },
        {
            original: gallery7,
            thumbnail: gallery7
        },
        {
            original: gallery8,
            thumbnail: gallery8
        },
        {
            original: gallery9,
            thumbnail: gallery9
        },
        {
            original: gallery10,
            thumbnail: gallery10
        },
        {
            original: gallery11,
            thumbnail: gallery11
        },

    ],
    PRESCHOOL: [
        {
            original: gallery,
            thumbnail: gallery
        },
        {
            original: gallery1,
            thumbnail: gallery1
        },
        {
            original: gallery2,
            thumbnail: gallery2
        },
        {
            original: gallery3,
            thumbnail: gallery3
        },
        {
            original: gallery4,
            thumbnail: gallery4
        },
        {
            original: gallery5,
            thumbnail: gallery5
        },
        {
            original: gallery6,
            thumbnail: gallery6
        },
        {
            original: gallery7,
            thumbnail: gallery7
        },
        {
            original: gallery8,
            thumbnail: gallery8
        },
        {
            original: gallery9,
            thumbnail: gallery9
        },
        {
            original: gallery10,
            thumbnail: gallery10
        },
        {
            original: gallery11,
            thumbnail: gallery11
        },
    ],
    PRIMARY: [
        {
            original: gallery,
            thumbnail: gallery
        },
        {
            original: gallery1,
            thumbnail: gallery1
        },
        {
            original: gallery2,
            thumbnail: gallery2
        },
        {
            original: gallery3,
            thumbnail: gallery3
        },
        {
            original: gallery4,
            thumbnail: gallery4
        },
        {
            original: gallery5,
            thumbnail: gallery5
        },
        {
            original: gallery6,
            thumbnail: gallery6
        },
        {
            original: gallery7,
            thumbnail: gallery7
        },
        {
            original: gallery8,
            thumbnail: gallery8
        },
        {
            original: gallery9,
            thumbnail: gallery9
        },
        {
            original: gallery10,
            thumbnail: gallery10
        },
        {
            original: gallery11,
            thumbnail: gallery11
        },
    ],
    JHS: [
        {
            original: gallery,
            thumbnail: gallery
        },
        {
            original: gallery1,
            thumbnail: gallery1
        },
        {
            original: gallery2,
            thumbnail: gallery2
        },
        {
            original: gallery3,
            thumbnail: gallery3
        },
        {
            original: gallery4,
            thumbnail: gallery4
        },
        {
            original: gallery5,
            thumbnail: gallery5
        },
        {
            original: gallery6,
            thumbnail: gallery6
        },
        {
            original: gallery7,
            thumbnail: gallery7
        },
        {
            original: gallery8,
            thumbnail: gallery8
        },
        {
            original: gallery9,
            thumbnail: gallery9
        },
        {
            original: gallery10,
            thumbnail: gallery10
        },
        {
            original: gallery11,
            thumbnail: gallery11
        },
    ],
    INTERNATIONAL_SECONDARY: [
        {
            original: gallery,
            thumbnail: gallery
        },
        {
            original: gallery1,
            thumbnail: gallery1
        },
        {
            original: gallery2,
            thumbnail: gallery2
        },
        {
            original: gallery3,
            thumbnail: gallery3
        },
        {
            original: gallery4,
            thumbnail: gallery4
        },
        {
            original: gallery5,
            thumbnail: gallery5
        },
        {
            original: gallery6,
            thumbnail: gallery6
        },
        {
            original: gallery7,
            thumbnail: gallery7
        },
        {
            original: gallery8,
            thumbnail: gallery8
        },
        {
            original: gallery9,
            thumbnail: gallery9
        },
        {
            original: gallery10,
            thumbnail: gallery10
        },
        {
            original: gallery11,
            thumbnail: gallery11
        },
    ]
}

const BOARD_OF_DIRECTORS = [
    {
        image: phillipa,
        name: 'Mrs. Phillipa  Aggrey - Mensah',
        occupation: 'Educationist',
        role: 'Board Chairperson',
    },
    {
        image: danny,
        name: 'Mr. Danny Mainoo',
        occupation: 'Business Executive',
        role: 'Vice Board Chairman',
    },
    {
        image: sheila,
        name: 'Ms. Sheila Acquah – Asare',
        occupation: 'Executive Director',
        role: 'Board Secretary',
    },
    {
        image: benson,
        name: 'Mr. Benson Blege',
        occupation: 'Chartered Accountant',
        role: 'Board Member',
    },
    {
        image: patience,
        name: 'Mrs. Patience Damptey',
        occupation: 'Environmental and Gender Consultant',
        role: 'Board Member',
    },
    {
        image: ophelia,
        name: 'Ms. Ophelia Ablorh',
        occupation: 'Human Resource Consultant',
        role: 'Board Member',
    },
    {
        image: lydia,
        name: 'Mrs. Lynda Folson',
        occupation: 'Marketing Executive',
        role: 'Board Member',
    },
];

const SRC = [
    {
        image: headGirlsPrefect,
        name: 'Britney Aframah Norman',
        role: 'Head Girls Prefect',
    },
    {
        image: headBoysPrefect,
        name: 'Jedidiah Agyefi-Mensah',
        role: 'Head Boys Prefect',
    },
    {
        image: assistantHeadBoys,
        name: 'Emmanuel Segbaya',
        role: 'Assistant Boys Prefect',
    },
    {
        image: assistantHeadGirls,
        name: 'Ethel Tay',
        role: 'Assistant Girls Prefect',
    }
];

const MANAGEMENT = [
    {
        image: sheila,
        name: 'Ms. Sheila Acquah-Asare',
        role: 'Director',
    },
    {
        image: valentia,
        name: 'Mrs. Vincentia Asase-Fayorsey',
        role: 'Principal (International Secondary)',
    },
    {
        image: seth,
        name: 'Mr. Seth Boakye Asirifi',
        role: 'Principal (Senior School)',
    },
    {
        image: peace,
        name: 'Ms. Peace Abasa-Addo',
        role: 'Principal (Junior School)',
    },
    {
        image: offeibia,
        name: 'Mrs. Offeibea Agyei Ketemefi',
        role: 'Administrator (Main School)',
    },
    {
        image: samuel,
        name: 'Mr. Samuel Ankoanna Gyimah',
        role: 'Financial Controller',
    },
    {
        image: christian,
        name: 'Mr. Christian K. Owusu',
        role: 'Property Manager',
    },
    {
        image: solomon,
        name: 'Mr. Solomon Adonoo',
        role: 'Upper Primary Department Head',
    },
    {
        image: victoria,
        name: 'Mrs. Victoria Adu Somuah',
        role: 'Junior Primary Department Head',
    },
    {
        image: quarshie,
        name: 'Mr. John Quarshie',
        role: 'Junior High School Department Head',
    }
];

const KEY_FEATURES = [
    {
        image: spaciousClassroom,
        name: 'Modern Spacious Classrooms'
    }, {
        image: spaciousClassroom,
        name: 'Jolly Phonics Education'
    },
    {
        image: montessoriEquipment,
        name: 'Montessori Equipment'
    },
    {
        image: spaciousClassroom,
        name: 'Well trained, experienced teachers'
    },
    {
        image: playgroundEquipment,
        name: 'Playground Equipment'
    },
    {
        image: healthySchoolMeals,
        name: 'Healthy School Meals (breakfast, lunch and snack, Optional)'
    },
    {
        image: security,
        name: '24 hour Security'
    },
];

const INTERNATIONAL_KEY_FEATURES = [
    {
        image: spaciousClassroom,
        name: 'Modern Spacious Classrooms'
    },
    {
        image: spaciousClassroom,
        name: 'Well-trained, experienced teachers'
    },
    {
        image: healthySchoolMeals,
        name: 'IT Laboratory (24-hour internet access)'
    },
    {
        image: healthySchoolMeals,
        name: 'Healthy School Meals'
    },
    {
        image: security,
        name: '24 hour Security'
    },
];

const PRIMARY_SCHOOL_KEY_FEATURES = [
    {
        image: spaciousClassroom,
        name: 'Modern spacious classrooms'
    },
    {
        image: spaciousClassroom,
        name: 'Basketball/Volleyball courts'
    },
    {
        image: spaciousClassroom,
        name: 'IT laboratory with 24 hour internet access'
    },
    {
        image: spaciousClassroom,
        name: 'Library'
    },
    {
        image: spaciousClassroom,
        name: 'Music room'
    },
    {
        image: security,
        name: '24 Hour Security'
    },
];

const JHS_KEY_FEATURES = [
    {
        image: spaciousClassroom,
        name: 'Modern spacious classrooms'
    },
    {
        image: spaciousClassroom,
        name: 'Sports Astro-Turf'
    },
    {
        image: spaciousClassroom,
        name: 'IT laboratory with 24 hour internet access'
    },
    {
        image: spaciousClassroom,
        name: 'Home Economics Equipment'
    },
    {
        image: spaciousClassroom,
        name: 'Library'
    },
    {
        image: spaciousClassroom,
        name: 'Pre-Technical Skills Practicals'
    },
    {
        image: security,
        name: '24 Hour Security'
    },
];

const INTERNATIONAL_SCHOOL_KEY_FACILITIES = [
    {
        image: spaciousClassroom,
        name: 'Air-Conditioned Classrooms'
    },
    {
        image: spaciousClassroom,
        name: 'IT Laboratory (24 hour internet access)'
    },
    {
        image: spaciousClassroom,
        name: 'Science Laboratory'
    },
    {
        image: spaciousClassroom,
        name: 'Library'
    },
    {
        image: spaciousClassroom,
        name: 'Grassed Field'
    },
    {
        image: security,
        name: 'School Cafeteria'
    },
    {
        image: spaciousClassroom,
        name: 'School Infirmary'
    },
    {
        image: security,
        name: 'Swimming Pool'
    },
];

const INTERNATIONAL_SECONDARY_SUBJECTS = [];

const PRESCHOOL_SUBJECTS = [
    {
        image: literacy,
        name: "Literacy (English)"
    },
    {
        image: creativeArts,
        name: "Creative Arts"
    },
    {
        image: numeracy,
        name: "Numeracy (Mathematics)"
    },
    {
        image: running,
        name: "Physical Education"
    },
    {
        image: science,
        name: "Science"
    },
    {
        image: spaciousClassroom,
        name: "Our World, Our People"
    },
    {
        image: music,
        name: "Music"
    }
];

const PRIMARY_SUBJECTS = [
    {
        image: spaciousClassroom,
        name: "English Language"
    },
    {
        image: spaciousClassroom,
        name: "French"
    },
    {
        image: numeracy,
        name: "Mathematics"
    },
    {
        image: spaciousClassroom,
        name: "Ga/Twi"
    },
    {
        image: science,
        name: "Science"
    },
    {
        image: creativeArts,
        name: "Creative Arts / Music"
    },
    {
        image: spaciousClassroom,
        name: "Information Communication and Technology"
    },
    {
        image: spaciousClassroom,
        name: "Religious & Moral Education"
    },
    {
        image: spaciousClassroom,
        name: "Our World, Our People"
    },
    {
        image: running,
        name: "Physical Education"
    },
    {
        image: music,
        name: "Reasoning Skills"
    },
];

const JHS_SUBJECTS = [
    {
        image: spaciousClassroom,
        name: "English Language & Literature"
    },
    {
        image: spaciousClassroom,
        name: "Mathematics"
    },
    {
        image: numeracy,
        name: "Social Studies"
    },
    {
        image: spaciousClassroom,
        name: "French"
    },
    {
        image: science,
        name: "Ghanaian Language (Ga/Twi) "
    },
    {
        image: creativeArts,
        name: "Integrated Science"
    },
    {
        image: spaciousClassroom,
        name: "Religious & Moral Education"
    },
    {
        image: spaciousClassroom,
        name: "Religious & Moral Education"
    },
    {
        image: spaciousClassroom,
        name: "Information Communication & Technology (ICT)"
    },
    {
        image: running,
        name: "Basic Design & Technology (BDT)"
    },
    {
        image: music,
        name: "Physical Education"
    },
];

const BENEFITS = [
    "International Certificate",
    "High Academic Standards",
    "Good student-teacher interaction",
    "Fun and educative extracurricular activities",
    "Strong focus on good Christian teaching",
    "Friendly and Positive Environment",
    "Excellent Location",
    "Affordability",
    "We Prepare you for TOMORROW!"
];

const PRE_IGCSE_SUBJECTS = [
    "Mathematics (Extended/Additional)",
    "English Language",
    "English Literature",
    "French",
    "Science",
    "History",
    "Geography",
    "Sociology",
    "Business Studies",
    "Economics",
    "Food & Nutrition",
    "Art & Design",
    "Information Technology",
    "Global Perspectives",
    "Reasoning Skills",
    "Physical Education",
];

const IGCSE_SUBJECTS = [
    "English Literature (Core)",
    "English Language (Core)",
    "Global Perspectives (Core)",
    "Mathematics (Core)",
    "Information Technology",
    "Biology",
    "Chemistry",
    "Physics",
    "Additional Mathematics",
    "Geography",
    "History",
    "Sociology",
    "French",
    "Accounting",
    "Business Studies",
    "Economics",
];

const A_LEVEL_SUBJECTS = [
    "Biology",
    "Chemistry",
    "Physics",
    "Mathematics",
    "English Literature",
    "French",
    "Sociology",
    "Economics",
    "Business Studies",
    "Accounting",
    "Information Technology",
    "Geography",
    "History",
];

const VALUES = [
    "Academic Excellence",
    "Leadership",
    "Integrity",
    "Responsibility",
    "Creativity",
    "Empathy",
    "Christian Values",
];

export const MOTHERCARE_SCHOOL_DATA = {
    VALUES,
    A_LEVEL_SUBJECTS,
    PRE_IGCSE_SUBJECTS,
    IGCSE_SUBJECTS,
    BENEFITS,
    KEY_FEATURES,
    BOARD_OF_DIRECTORS,
    MANAGEMENT,
    PRESCHOOL_SUBJECTS,
    PRIMARY_SUBJECTS,
    INTERNATIONAL_SECONDARY_SUBJECTS,
    PRIMARY_SCHOOL_KEY_FEATURES,
    INTERNATIONAL_KEY_FEATURES,
    INTERNATIONAL_SCHOOL_KEY_FACILITIES,
    GALLERY,
    LEADERSHIP,
    JHS_KEY_FEATURES,
    JHS_SUBJECTS,
    SRC
};
