import './App.css';
import HomePage from "./pages/home/home-page";
import AboutUsPage from "./pages/about/about-us-page";
import ContactUsPage from "./pages/contact-us/contact-us-page";
import NotFoundPage from "./pages/404/not-found-page";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Route, Routes} from "react-router";
import DepartmentsPage from "./pages/departments/departments-page";
import PreschoolPage from "./pages/departments/pre-school-page";
import PrimarySchoolPage from "./pages/departments/primary-school-page";
import InternationalSecondaryPage from "./pages/departments/international-secondary-page";
import {selectUI} from "./redux/features/ui/ui-slice";
import {useSelector} from "react-redux";
import {THEMES} from "./utils/themes";
import NewsPage from "./pages/news/news-page";
import JHSPage from "./pages/departments/jhs-page";
import ArticlesPage from "./pages/news/articles-page";
import InstagramPage from "./pages/news/instagram-page";
import TwitterPage from "./pages/news/twitter-page";
import FacebookPage from "./pages/news/facebook-page";
import LeadershipPage from "./pages/leadership/leadership-page";
import AlumniPage from "./pages/alumni/alumni-page";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import CalendarPage from "./pages/calendar/calendar-page";

function App() {

    const {theme} = useSelector(selectUI);
    const location = useLocation();

    useEffect(() => {
        window.scroll({top: 0, left: 0, behavior: "smooth"});
    }, [location.pathname]);

    return (
        <ThemeProvider theme={theme === 'dark' ? THEMES.dark : THEMES.light}>
            <CssBaseline enableColorScheme={true}/>
            <AnimatePresence mode="wait" initial={true} presenceAffectsLayout={true}>
                <Routes location={location} key={location.key}>
                    <Route element={<HomePage/>} path="/" exact={true}/>
                    <Route element={<AboutUsPage/>} path="/about" exact={true}/>
                    <Route element={<DepartmentsPage/>} path="/departments" exact={true}/>
                    <Route element={<PreschoolPage/>} path="/departments/preschool" exact={true}/>
                    <Route element={<JHSPage/>} path="/departments/jhs" exact={true}/>
                    <Route element={<PrimarySchoolPage/>} path="/departments/primary" exact={true}/>
                    <Route element={<InternationalSecondaryPage/>} path="/departments/international" exact={true}/>
                    <Route element={<ContactUsPage/>} path="/contact" exact={true}/>
                    <Route element={<LeadershipPage/>} path="/leadership" exact={true}/>
                    <Route element={<CalendarPage/>} path="/calendar" exact={true}/>
                    <Route element={<AlumniPage/>} path="/alumni" exact={true}/>
                    <Route path="/news" exact={true}>
                        <Route index={true} element={<NewsPage/>}/>
                        <Route path="facebook" element={<FacebookPage/>}/>
                        <Route path="twitter" element={<TwitterPage/>}/>
                        <Route path="instagram" element={<InstagramPage/>}/>
                        <Route path="articles" element={<ArticlesPage/>}/>
                    </Route>
                    <Route element={<NotFoundPage/>} path="*" exact={true}/>
                </Routes>
            </AnimatePresence>
        </ThemeProvider>
    );
}

export default App;
