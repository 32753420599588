import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Chip,
    Divider,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {UTILS} from "../../utils/utils";
import moment from "moment";
import {InfoOutlined, Share} from "@mui/icons-material";

const Article = ({article}) => {
    const {banner, title, slug, excerpt, category, tags, createdAt, author: {name}} = article;
    return (
        <Card
            elevation={0}
            sx={{
                height: "100%",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 32,
                borderTopLeftRadius: 32,
                borderTopRightRadius: 0,
            }}>
            <CardHeader
                sx={{alignItems: "center"}}
                title={name}
                subheader={
                    <Typography
                        variant="body2"
                        sx={{color: "text.secondary", fontSize: 10}}>{moment(createdAt).fromNow()}</Typography>
                }
                avatar={
                    <Avatar
                        sx={{
                            backgroundColor: "light.secondary",
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 12,
                            borderTopLeftRadius: 12,
                            borderTopRightRadius: 0,
                        }}>
                        <Typography
                            variant="h6"
                            sx={{color: "secondary.main",}}>{UTILS.getInitials(name)}
                        </Typography>
                    </Avatar>}
            />
            <CardMedia component="img" src={banner} sx={{height: 250, objectFit: "cover", objectPosition: "center"}}/>
            <CardContent>
                <Stack direction="column" spacing={1}>
                    <Link to={`/articles/${slug}`} style={{textDecoration: "none"}}>
                        <Typography variant="h6" sx={{color: "text.primary"}}>{title}</Typography>
                    </Link>
                    <Typography variant="body2" sx={{color: "text.secondary"}}>{excerpt}</Typography>
                    <Box>
                        <Grid container={true} spacing={2}>
                            {tags.map((tag, index) => {
                                return (
                                    <Grid key={index} item={true}>
                                        <Chip
                                            label={tag}
                                            title={tag}
                                            size="small"
                                            variant="outlined"
                                            sx={{
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 12,
                                                borderTopLeftRadius: 12,
                                                borderTopRightRadius: 0,
                                            }}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                    <Typography variant="body2" sx={{color: "text.secondary"}}>{category}</Typography>
                </Stack>
            </CardContent>
            <Divider variant="fullWidth" light={true}/>
            <CardActions>
                <Grid container={true}>
                    <Grid
                        xs={6}
                        item={true}>
                        <Button
                            color="secondary"
                            variant="text"
                            startIcon={<Share color="secondary" fontSize="small"/>}
                            size="small" sx={{textTransform: "none"}}>
                            Share
                        </Button>
                    </Grid>
                    <Grid
                        xs={6}
                        item={true}>
                        <Button
                            color="secondary"
                            variant="text"
                            startIcon={<InfoOutlined color="secondary" fontSize="small"/>}
                            size="small" sx={{textTransform: "none"}}>
                            Details
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}

export default Article;
