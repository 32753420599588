import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";


const getTweets = createAsyncThunk("twitter/getTweets", async ({}) => {
    try {

    }catch (e) {

    }
});


const twitterSlice = createSlice({
    name: "twitter",
    initialState: {
        twitterLoading: false,
        twitterError: null,
        tweets: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getTweets.pending, (state) => {
            state.twitterLoading = true;
            state.twitterError = null;
        }).addCase(getTweets.fulfilled, (state, action) => {
            state.tweets = action.payload;
            state.twitterLoading = false;
            state.twitterError = null;
        }).addCase(getTweets.rejected, (state, action) => {
            state.tweets = [];
            state.twitterLoading = false;
            state.twitterError = action.payload;
        })
    }
});


const {reducer} = twitterSlice;

export const selectTweets = state => state.twitter;

export default reducer;
