import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const getPosts = createAsyncThunk("instagram/getPosts", async ({}) => {
    try {

    }catch (e) {

    }
});

const instagramSlice = createSlice({
    name: "instagram",
    initialState: {
        instagramLoading: false,
        instagramError: null,
        instagramPosts: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPosts.pending, (state) => {
            state.instagramLoading = true;
            state.instagramError = null;
        }).addCase(getPosts.fulfilled, (state, action) => {
            state.instagramPosts = action.payload;
            state.instagramLoading = false;
            state.instagramError = null;
        }).addCase(getPosts.rejected, (state, action) => {
            state.instagramPosts = [];
            state.instagramLoading = false;
            state.instagramError = action.payload;
        })
    }
});


const {reducer} = instagramSlice;

export const selectInstagram = state => state.instagram;

export default reducer;
