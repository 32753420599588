import Layout from "../../components/layout/layout";
import banner from "../../assets/images/banner.jpg";
import {Box, Container, Grid, Typography} from "@mui/material";
import {MOTHERCARE_SCHOOL_DATA} from "../../utils/data";
import Leader from "../../components/shared/leader";

const LeadershipPage = () => {
    return (
        <Layout>
            <Box
                sx={{
                    position: 'relative',
                    pt: 12.5,
                    minHeight: {xs: '50vh', lg: '100vh'},
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('${banner}')`,
                    backgroundAttachment: 'scroll',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingY: {xs: 4, lg: 0},
                    backgroundBlendMode: 'overlay',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}>
                <Container>
                    <Typography align="center" variant="h3" sx={{color: 'white', mb: 4}}>
                        Leadership
                    </Typography>
                    <Typography align="center" variant="h6" sx={{color: 'white', mb: 4}}>
                        Check us out on social media
                    </Typography>
                </Container>
            </Box>


            <Box sx={{py: 8, backgroundColor: "background.paper"}}>
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main", mb: 4}}>
                        BOARD OF GOVERNORS
                    </Typography>

                    <Grid container={true} spacing={4}>
                        {MOTHERCARE_SCHOOL_DATA.LEADERSHIP.BOARD_OF_GOVERNORS.map((leader, index) => {
                            return (
                                <Grid item={true} xs={12} md={4} lg={3} key={index}>
                                    <Leader leader={leader}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.default"}}>
                <Typography variant="h4" align="center" sx={{color: "secondary.main", mb: 4}}>
                    MANAGEMENT AND FACULTY
                </Typography>

                <Grid container={true} spacing={4}>
                    {MOTHERCARE_SCHOOL_DATA.LEADERSHIP.MANAGEMENT_AND_FACULTY.map((leader, index) => {
                        return (
                            <Grid item={true} xs={12} md={4} lg={3} key={index}>
                                <Leader leader={leader}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.paper"}}>
                <Typography variant="h4" align="center" sx={{color: "secondary.main", mb: 4}}>
                    SRC
                </Typography>

                <Grid container={true} spacing={4}>
                    {MOTHERCARE_SCHOOL_DATA.LEADERSHIP.SRC.map((leader, index) => {
                        return (
                            <Grid item={true} xs={12} md={4} lg={3} key={index}>
                                <Leader leader={leader}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </Layout>
    )
}

export default LeadershipPage;
