import {Stack, Toolbar} from "@mui/material";
import {DarkModeOutlined, LightModeOutlined, Menu} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import {Link} from "react-router-dom";
import logo from "../../assets/images/logo.png";
import mcshighlogo from "../../assets/images/mcshighlogo.png";

const MobileHeader = () => {

    const dispatch = useDispatch();

    const {theme} = useSelector(selectUI);

    return (
        <Toolbar>
            <Stack
                sx={{width: '100%'}}
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}>
                    <Menu
                        color="secondary"
                        sx={{
                            padding: 0.8,
                            fontSize: 36,
                            cursor: 'pointer',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 16,
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 0,
                            backgroundColor: 'light.secondary'
                        }}
                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleDrawer(true))}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <Link to='/' style={{textDecoration: 'none', display: 'block'}}>
                            <img src={logo} style={{height: 70}} alt="MIS logo"/>
                        </Link>
                        <Link to='/' style={{textDecoration: 'none', display: 'block'}}>
                            <img src={mcshighlogo} style={{height: 70}} alt="MIS logo"/>
                        </Link>
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    {theme === 'dark' ? (
                        <LightModeOutlined
                            color="secondary"
                            onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                            sx={{
                                padding: 0.8,
                                fontSize: 36,
                                cursor: 'pointer',
                                backgroundColor: 'light.secondary',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 16,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 0,
                            }}
                        />
                    ) : (
                        <DarkModeOutlined
                            color="secondary"
                            onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                            sx={{
                                padding: 0.8,
                                fontSize: 36,
                                cursor: 'pointer',
                                backgroundColor: 'light.secondary',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 16,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 0,
                            }}
                        />
                    )}
                </Stack>
            </Stack>
        </Toolbar>
    )
}

export default MobileHeader;
