import {Container, Grid, Stack, Typography} from "@mui/material";
import SocialLink from "./social-link";
import {Call, FacebookOutlined, Instagram, Mail, Twitter} from "@mui/icons-material";

const Copyright = () => {
    return (
        <Container sx={{paddingY: 4}}>
            <Grid container={true} spacing={4} justifyContent="space-between">
                <Grid item={true} xs={12} md="auto">
                    <Typography
                        variant="body1"
                        sx={{color: 'text.primary', fontWeight: 500, textAlign: {xs: "center", lg: "left"}}}>
                        Copyright © 2022 Mothercare International School. All Rights Reserved
                    </Typography>
                </Grid>
                <Grid item={true} xs={12} md="auto">
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <SocialLink
                            icon={
                                <FacebookOutlined
                                    sx={{
                                        backgroundColor: 'light.secondary',
                                        color: "secondary.main",
                                        fontSize: 32,
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 12,
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 0,
                                        padding: 0.5
                                    }}/>}
                            link="https://www.facebook.com/mothercaregh"
                        />
                        <SocialLink
                            icon={
                                <Instagram
                                    sx={{
                                        backgroundColor: 'light.secondary',
                                        color: "secondary.main",
                                        fontSize: 32,
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 12,
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 0,
                                        padding: 0.5
                                    }}
                                />}
                            link="https://instagram.com/mothercareschool_gh"
                        />
                        <SocialLink
                            icon={
                                <Twitter
                                    sx={{
                                        backgroundColor: 'light.secondary',
                                        color: "secondary.main",
                                        fontSize: 32,
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 12,
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 0,
                                        padding: 0.5
                                    }}
                                />}
                            link="https://twitter.com/mothercareschool_gh"
                        />
                        <SocialLink
                            icon={
                                <Mail
                                    sx={{
                                        backgroundColor: 'light.secondary',
                                        color: "secondary.main",
                                        fontSize: 32,
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 12,
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 0,
                                        padding: 0.5
                                    }}
                                />}
                            link="mailto:info@mothercareschool.edu.gh"
                        />
                        <SocialLink
                            icon={
                                <Call
                                    sx={{
                                        backgroundColor: 'light.secondary',
                                        color: "secondary.main",
                                        fontSize: 32,
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 12,
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 0,
                                        padding: 0.5
                                    }}
                                />}
                            link="tel:+233302778564"
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    )
}
export default Copyright;
