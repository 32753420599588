const MOTHERCARE_THEME_VARIANT = 'MOTHERCARE_THEME_VARIANT';
const MOTHERCARE_VIEW = 'MOTHERCARE_VIEW';
// const SERVER_BASE_URL = 'http://localhost:5000/api/v1/user';
const SERVER_BASE_URL = 'https://emiratesndbbackend.herokuapp.com/api/v1/user';

export const CONSTANTS = {
    MOTHERCARE_THEME_VARIANT,
    MOTHERCARE_VIEW,
    SERVER_BASE_URL
};
