import banner from "./../../../assets/images/banners/IMG_6593.jpg";

export const articles = [
    {
        _id: 1,
        title: "Lorem Ipsum dolor",
        excerpt: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        category: "Weekly Awards",
        author: {
            name: "Inigo Lopez"
        },
        createdAt: new Date(),
        banner,
        tags: ["lorem", "ipsum"]
    }
]
