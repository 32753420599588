import {Button, Menu, MenuItem, Stack, Toolbar, Link as MUILink} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import NavbarLink from "../shared/navbar-link";
import {useState} from "react";
import {DarkModeOutlined, KeyboardArrowDown, KeyboardArrowUp, LightModeOutlined} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import logo from "./../../assets/images/logo.png";
import mcshighlogo from "./../../assets/images/mcshighlogo.png";

const DesktopHeader = () => {

    const {pathname} = useLocation();
    const {theme} = useSelector(selectUI);
    const dispatch = useDispatch();

    const [menuOpen, setMenuOpen] = useState(false);
    const [newsMenuOpen, setNewsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [newsAnchorEl, setNewsAnchorEl] = useState(null);

    const handleMenuOpen = event => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    }

    const handleNewsMenuOpen = event => {
        setNewsAnchorEl(event.currentTarget);
        setNewsMenuOpen(true);
    }

    const handleNewsMenuClose = () => {
        setNewsAnchorEl(null);
        setNewsMenuOpen(false);
    }

    return (
        <Toolbar>
            <Stack
                sx={{width: '100%'}}
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Link to='/' style={{textDecoration: 'none', display: 'block'}}>
                        <img src={logo} style={{height: 100}} alt="MIS logo"/>
                    </Link>
                    <Link to='/' style={{textDecoration: 'none', display: 'block'}}>
                        <img src={mcshighlogo} style={{height: 100}} alt="MIS logo"/>
                    </Link>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <NavbarLink active={pathname === '/'} label="Home" path="/"/>
                    <NavbarLink active={pathname === '/about'} label="About Us" path="/about"/>
                    <Button
                        size="large"
                        endIcon={menuOpen ? <KeyboardArrowUp color="secondary"/> :
                            <KeyboardArrowDown sx={{
                                color:
                                    ['/departments', '/departments/preschool', '/departments/primary', '/departments/international', '/departments/jhs'].includes(pathname) ? 'secondary.main' : 'text.secondary'
                            }}/>}
                        onClick={handleMenuOpen}
                        variant="text"
                        sx={{
                            textTransform: 'capitalize',
                            color:
                                ['/departments', '/departments/preschool', '/departments/primary', '/departments/international', '/departments/jhs'].includes(pathname) ? 'secondary.main' : 'text.secondary'
                        }}>
                        Departments
                    </Button>
                    <NavbarLink active={pathname === '/contact'} label="Contact Us" path="/contact"/>
                    <Button
                        size="large"
                        endIcon={newsMenuOpen ? <KeyboardArrowUp color="secondary"/> :
                            <KeyboardArrowDown sx={{
                                color:
                                    ['/departments', '/departments/preschool', '/departments/primary', '/departments/international', '/departments/jhs'].includes(pathname) ? 'secondary.main' : 'text.secondary'
                            }}/>}
                        onClick={handleNewsMenuOpen}
                        variant="text"
                        sx={{
                            textTransform: 'capitalize',
                            color:
                                ['/news', '/news/facebook', '/news/twitter', '/news/instagram', '/news/articles'].includes(pathname) ? 'secondary.main' : 'text.secondary'
                        }}>
                        News
                    </Button>

                    <Menu
                        onClose={handleNewsMenuClose}
                        anchorEl={newsAnchorEl}
                        elevation={1}
                        open={newsMenuOpen}>
                        <MenuItem>
                            <NavbarLink
                                active={pathname === '/news'}
                                label="Overview"
                                path="/news"
                            />
                        </MenuItem>
                        <MenuItem>
                            <NavbarLink
                                active={pathname === '/news/facebook'}
                                label="Facebook"
                                path="/news/facebook"
                            />
                        </MenuItem>
                        <MenuItem>
                            <NavbarLink
                                active={pathname === '/news/twitter'}
                                label="Twitter"
                                path="/news/twitter"
                            />
                        </MenuItem>
                        <MenuItem>
                            <NavbarLink
                                active={pathname === '/news/instagram'}
                                label="Instagram"
                                path="/news/instagram"
                            />
                        </MenuItem>
                        <MenuItem>
                            <NavbarLink
                                active={pathname === '/news/articles'}
                                label="Articles"
                                path="/news/articles"
                            />
                        </MenuItem>
                    </Menu>

                    <Menu
                        onClose={handleMenuClose}
                        anchorEl={anchorEl}
                        elevation={1}
                        open={menuOpen}>
                        <MenuItem>
                            <NavbarLink
                                active={pathname === '/departments'}
                                label="Overview"
                                path="/departments"
                            />
                        </MenuItem>
                        <MenuItem>
                            <NavbarLink
                                active={pathname === '/departments/preschool'}
                                label="Preschool"
                                path="/departments/preschool"
                            />
                        </MenuItem>
                        <MenuItem>
                            <NavbarLink
                                active={pathname === '/departments/primary'}
                                label="Primary"
                                path="/departments/primary"
                            />
                        </MenuItem>
                        <MenuItem>
                            <NavbarLink
                                active={pathname === '/departments/jhs'}
                                label="JHS"
                                path="/departments/jhs"
                            />
                        </MenuItem>
                        <MenuItem>
                            <NavbarLink
                                active={pathname === '/departments/international'}
                                label="International Secondary"
                                path="/departments/international"
                            />
                        </MenuItem>
                    </Menu>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Link to="/departments" style={{textDecoration: 'none'}}>
                        <Button
                            sx={{
                                textTransform: 'capitalize',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 16,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 0,
                            }}
                            disableElevation={true}
                            variant="contained"
                            color="secondary"
                            size="large">
                            Enroll Now
                        </Button>
                    </Link>
                    <MUILink
                        target="_blank"
                        underline="none"
                        href="https://mothercareschoolportal.vercel.app/">
                        <Button
                            sx={{
                                textTransform: 'capitalize',
                                borderWidth: 2,
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 16,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 0
                            }}
                            disableElevation={true}
                            variant="outlined"
                            color="secondary"
                            size="large">
                            Community Portal
                        </Button>
                    </MUILink>
                    {theme === 'dark' ? (
                        <LightModeOutlined
                            color="secondary"
                            onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                            sx={{
                                padding: 0.6,
                                fontSize: 36,
                                cursor: 'pointer',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 16,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 16,
                                backgroundColor: 'light.secondary'
                            }}/>
                    ) : (
                        <DarkModeOutlined
                            color="secondary"
                            onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                            sx={{
                                padding: 0.6,
                                fontSize: 36,
                                cursor: 'pointer',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 16,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 16,
                                backgroundColor: 'light.secondary'
                            }}
                        />
                    )}
                </Stack>
            </Stack>
        </Toolbar>
    )
}

export default DesktopHeader;
