import Layout from "../../components/layout/layout";
import banner from "../../assets/images/banner.jpg";
import {Box, Card, CardContent, Container, Grid, Link, Stack, Typography} from "@mui/material";
import {CallOutlined, LocationOnOutlined, MailOutlined, WhatsApp} from "@mui/icons-material";
import ContactForm from "../../components/shared/contact-form";

const ContactUsPage = () => {

    return (
        <Layout>
            <Box
                sx={{
                    position: 'relative',
                    pt: 12.5,
                    minHeight: {xs: '50vh', lg: '100vh'},
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('${banner}')`,
                    backgroundAttachment: 'scroll',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingY: {xs: 4, lg: 0},
                    backgroundBlendMode: 'overlay',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}>
                <Container>
                    <Typography align="center" variant="h3" sx={{color: 'white', mb: 4}}>
                        Contact Us
                    </Typography>
                    <Typography align="center" variant="h6" sx={{color: 'white', mb: 4}}>
                        We're always happy to hear your thoughts
                    </Typography>
                </Container>
            </Box>


            <Box sx={{py: 8}}>
                <Container maxWidth="xl">
                    <Typography variant="h5" align="center" sx={{color: 'text.primary', mb: 4}}>
                        Contact Mothercare Main Campus
                    </Typography>
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={3}>
                            <Link href="#" rel="noopener" underline="none">
                                <Card
                                    sx={{
                                        borderBottomRightRadius: 16,
                                        borderTopRightRadius: 16,
                                        borderBottomLeftRadius: 16,
                                        borderTopLeftRadius: 0,
                                        height: '100%'
                                    }} elevation={0}>
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <LocationOnOutlined
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                Address
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                4 Giffard Street-East Cantonment, Accra Ghana
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>


                        <Grid item={true} xs={12} md={3}>
                            <Link href="mailto:info@mothercareschool.edu.gh" target="_blank" underline="none">
                                <Card
                                    sx={{
                                        borderBottomRightRadius: 16,
                                        borderTopRightRadius: 16,
                                        borderBottomLeftRadius: 16,
                                        borderTopLeftRadius: 0,
                                        height: '100%'
                                    }} elevation={0}>
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <MailOutlined
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                Email
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                info@mothercareschool.edu.gh
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>


                        <Grid item={true} xs={12} md={3}>
                            <Link href="tel:+2330302778564" target="_blank" underline="none">
                                <Card
                                    sx={{
                                        borderBottomRightRadius: 8,
                                        borderTopRightRadius: 34,
                                        borderBottomLeftRadius: 34,
                                        borderTopLeftRadius: 8,
                                        height: '100%'
                                    }} elevation={0}>
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <CallOutlined
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                Phone
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                +233 (030) 277 - 8564
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>

                        <Grid item={true} xs={12} md={3}>
                            <Link
                                href="https://api.whatsapp.com/send?phone=233544864949"
                                target="_blank"
                                underline="none">
                                <Card
                                    sx={{
                                        borderBottomRightRadius: 8,
                                        borderTopRightRadius: 34,
                                        borderBottomLeftRadius: 34,
                                        borderTopLeftRadius: 8,
                                        height: '100%'
                                    }} elevation={0}>
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <WhatsApp
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                WhatsApp
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                +233 (054) - 486 - 4949
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: "background.paper"
                }}>
                <Container maxWidth="xl">
                    <Typography variant="h5" align="center" sx={{color: 'text.primary', mb: 4}}>
                        Contact MSC High (International Secondary)
                    </Typography>
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={3}>
                            <Link href="#" rel="noopener" underline="none">
                                <Card
                                    variant="outlined"
                                    sx={{
                                        borderBottomRightRadius: 16,
                                        borderTopRightRadius: 16,
                                        borderBottomLeftRadius: 16,
                                        borderTopLeftRadius: 0,
                                        height: '100%'
                                    }}>
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <LocationOnOutlined
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                Address
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                MSC International High Tesano, Accra Ghana
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>

                        <Grid item={true} xs={12} md={3}>
                            <Link href="mailto:info@mothercareschool.edu.gh" underline="none">
                                <Card
                                    sx={{
                                        borderBottomRightRadius: 16,
                                        borderTopRightRadius: 16,
                                        borderBottomLeftRadius: 16,
                                        borderTopLeftRadius: 0,
                                        height: '100%'
                                    }} variant="outlined">
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <MailOutlined
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                Email
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                info@mcshigh.com
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>

                        <Grid item={true} xs={12} md={3}>
                            <Link href="tel:+233(0)302261609" underline="none">
                                <Card
                                    sx={{
                                        borderBottomRightRadius: 8,
                                        borderTopRightRadius: 34,
                                        borderBottomLeftRadius: 34,
                                        borderTopLeftRadius: 8,
                                        height: '100%'
                                    }} variant="outlined">
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <CallOutlined
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                Phone
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                +233 (0)302 261 609
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>

                        <Grid item={true} xs={12} md={3}>
                            <Link href="https://api.whatsapp.com/send?phone=233208564485" underline="none">
                                <Card
                                    sx={{
                                        borderBottomRightRadius: 8,
                                        borderTopRightRadius: 34,
                                        borderBottomLeftRadius: 34,
                                        borderTopLeftRadius: 8,
                                        height: '100%'
                                    }} variant="outlined">
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <WhatsApp
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                WhatsApp
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                +233 (0)208 564 485
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: 'background.default'}}>
                <ContactForm
                    title="Send Message"
                    caption="Send us a message and our customer support will help you find answers to your questions."
                />
            </Box>

        </Layout>
    )
}

export default ContactUsPage;
