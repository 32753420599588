import {Card, CardContent, Stack, Typography} from "@mui/material";

const Step = ({number, children}) => {
    return (
        <Stack sx={{
            width: "100%",
            height: "100%",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 16,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 0,
        }} direction="row" spacing={4}>
            <Typography variant="h2" sx={{color: "light.secondary"}}>
                {number}
            </Typography>
            <Card
                sx={{
                    flex: 1,
                    height: "100%",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 32,
                    borderTopLeftRadius: 32,
                    borderTopRightRadius: 0,
                }}
                elevation={0}
                variant="elevation">
                <CardContent>
                    {children}
                </CardContent>
            </Card>
        </Stack>
    )
}

export default Step;
