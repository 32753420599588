import {Stack, Typography} from "@mui/material";

const Activity = ({label, color, backgroundColor}) => {
    return (
        <Stack sx={{backgroundColor, borderRadius: 2, padding: 2}} spacing={3} direction="row" alignItems="center">
            <Typography variant="h5" sx={{fontWeight: 700, color}}>{label}</Typography>
        </Stack>
    )
}

export default Activity;
