import {Avatar, Card, CardContent, Stack, Typography} from "@mui/material";

const CoreValue = ({value}) => {
    return (
        <Stack sx={{height: "100%", width: "100%"}} direction="row" spacing={2} alignItems="center">
            <Avatar
                variant="circular"
                sx={{
                    backgroundColor: "light.secondary",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 16,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 0,
                }}>
                <Typography
                    variant="h4"
                    sx={{color: "secondary.main"}}>
                    {value[0]}
                </Typography>
            </Avatar>
            <Card
                variant="elevation"
                sx={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 16,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 0,
                    backgroundColor: "background.paper",
                    flex: 1
                }} elevation={0}>
                <CardContent>
                    <Stack direction="column" spacing={2}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: "text.primary",
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 16,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 0,
                                fontWeight: "bold"
                            }}>
                            {value}
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    )
}

export default CoreValue;
