import {Card} from "@mui/material";

const InstagramPost = ({post}) => {
    return (
        <Card>

        </Card>
    )
}

export default InstagramPost;
