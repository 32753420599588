import Layout from "../../components/layout/layout";
import smBanner from "../../assets/images/banners/IMG_6593_czasw8_c_scale,w_908.jpg";
import mdBanner from "../../assets/images/banners/IMG_6593_czasw8_c_scale,w_1116.jpg";
import lgBanner from "../../assets/images/banners/IMG_6593_czasw8_c_scale,w_1400.jpg";

import {
    Box,
    CardMedia,
    Container,
    Grid,
    Link,
    Link as MUILink, Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Step from "../../components/shared/step";
import admissionForm from "../../assets/docs/MCEnrolmentForm2022.pdf";
import registrationForm from "../../assets/docs/MCEnrolmentForm2022.pdf";
import mscHighEnrollmentForm from "./../../assets/docs/IGCSEEnrolmentForm2022.pdf";
import {useEffect, useState} from "react";
import useWidth from "../../hooks/use-width";
import objectives from "../../assets/images/objectives.png";
import counselling from "../../assets/images/counselling.jpg";
import trips from "../../assets/images/trips.jpg";

const DepartmentsPage = () => {

    const xs = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme => theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const width = useWidth();

    const [banner, setBanner] = useState(truth => {
        switch (truth) {
            case xs:
                return smBanner;
            case md:
                return mdBanner;
            case lg:
                return lgBanner;
        }
    });

    useEffect(() => {
        if (width < 900) {
            setBanner(smBanner);
        } else if (width >= 900 < 1200) {
            setBanner(mdBanner);
        } else if (width >= 1200) {
            setBanner(lgBanner);
        }
    }, [width])

    return (
        <Layout>
            <Box
                sx={{
                    position: 'relative',
                    pt: 12.5,
                    minHeight: {xs: '50vh', lg: '100vh'},
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('${banner}')`,
                    backgroundAttachment: 'scroll',
                    backgroundPosition: 'top',
                    backgroundSize: {xs: 'cover', lg: 'cover'},
                    backgroundRepeat: 'no-repeat',
                    paddingY: {xs: 4, lg: 0},
                    backgroundBlendMode: 'multiply',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}>
                <Container>
                    <Typography align="center" variant="h4" sx={{color: 'white', mb: 4}}>
                        Departments
                    </Typography>
                    <Typography align="center" variant="h6" sx={{color: 'white', mb: 4}}>
                        Overview of our various departments
                    </Typography>
                </Container>
            </Box>

            <Box sx={{minHeight: '30vh', alignItems: 'center', py: 8}}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4} alignItems="center">
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                Our Educational Objectives
                            </Typography>

                            <Stack direction="column" spacing={1}>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    To maintain a high academic standard that delivers a broad curriculum and draws from
                                    various teaching methods.
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    To encourage participation in co-curricular activities.
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    To promote positive personal values that cultivate integrity in students and also
                                    prepare students to become responsible, creative, self-reliant, and productive
                                    members
                                    of the National and Global community.
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    To ensure understanding of and respect of diversity that promotes knowledge and
                                    understanding of Ghana.
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    To develop a positive educational facility that provides a nurturing, secure, clean
                                    and
                                    friendly learning environment where students can grow intellectually, socially,
                                    emotionally and physically.
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    To promote understanding of and participation in National and Global concerns that
                                    encourage students to regard the natural world as their inheritance and their
                                    responsibility.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={objectives}
                                sx={{height: '100%', width: '100%',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 32,
                                    borderTopLeftRadius: 32,
                                    borderTopRightRadius: 0,}}/>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box sx={{minHeight: '30vh', alignItems: 'center', py: 8, backgroundColor: "background.paper"}}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4} alignItems="center">
                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={counselling}
                                sx={{height: '100%', width: '100%',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 32,
                                    borderTopLeftRadius: 32,
                                    borderTopRightRadius: 0,}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                Counselling Service
                            </Typography>

                            <Stack direction="column" spacing={1}>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    Career planning
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    Ghanaian and international university applications support
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    University admissions interview preparation
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    Study skills support
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    Personal/Social development support
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    Counselling on personal problems affecting students academic work or general
                                    well-being
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{minHeight: '30vh', alignItems: 'center', py: 8}}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4} alignItems="center">
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                Local and International Trips
                            </Typography>

                            <Stack direction="column" spacing={1}>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    Local and international excursions are organised regularly to allow our students to
                                    experience practical aspects of the academic curriculum and gain local and
                                    international exposure.
                                </Typography>
                                <Typography variant="body2" sx={{color: 'text.primary'}}>
                                    In addition to visits across Ghana, countries our students have visited include the
                                    USA, UK, Denmark, Holland, Togo and Benin.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={trips}
                                sx={{height: '100%', width: '100%',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 32,
                                    borderTopLeftRadius: 32,
                                    borderTopRightRadius: 0,}}/>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box
                id="steps"
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        Admission into MCS International High
                    </Typography>

                    <Typography
                        variant="body2"
                        sx={{color: 'text.primary', mb: 4}}>
                        MCS International High boasts of an excellent track record of high academic standards with
                        outstanding results in the IGCSE and A Levels. MCS International High can also boast of students
                        gaining scholarships into universities due to the exemplary results they obtained in their
                        examinations.
                    </Typography>

                    <Grid sx={{mb: 4}} container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="01">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Download registration form
                                </Typography>

                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Download registration form here{' '}
                                    <MUILink
                                        sx={{color: "secondary.main"}}
                                        href={mscHighEnrollmentForm}
                                        download="IGCSEEnrolmentForm2022.docx">
                                        (click now)
                                    </MUILink>{' '}
                                    / collect from the Administrative office here {' '}
                                    <MUILink
                                        sx={{color: "secondary.main"}}
                                        href="https://goo.gl/maps/BbScxLk4gaJGxUUD9"
                                        target="_blank">
                                        (click now to locate us page).
                                    </MUILink>
                                    Download Form
                                </Typography>

                            </Step>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="02">
                                <Typography variant="h6" sx={{color: "text.primary", mb: 2}}>
                                    Submit completed application form with the following:
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 2}}>
                                    Completed medical and enrolment form (included in application pack)
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 2}}>
                                    Birth Certificate/ Vaccination card/ Copy of passport
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 2}}>
                                    Last 2 terms reports
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    2 passport sized pictures (Less than 6 months old)
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="03">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Complete entrance examinations in Math, English and/or Science
                                </Typography>
                                <Typography variant="body2" sx={{color: "secondary.main", mb: 2}}>
                                    Examination subjects differ depending on level of entry and subject chosen
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 2}}>
                                    Examination may be taken on various dates between May and August on school premises
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 2}}>
                                    Students will only be permitted to write the entrance exam if there are vacancies
                                    in the class they require
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 2}}>
                                    Students entering Sixth form must have at least five IGCSE passes and may be
                                    required to take an exam depending on IGCSE grades.
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="04">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Attend an interview (For applicants who successfully meet entrance requirements)
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Receive offer of placement letter
                                </Typography>
                            </Step>
                        </Grid>
                    </Grid>
                    <Typography
                        align="center"
                        variant="h6"
                        sx={{color: "secondary.main", textTransform: "uppercase"}}>
                        Receive an offer of admission
                    </Typography>
                </Container>
            </Box>

            <Box
                id="steps"
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.default'
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        Admission into JHS
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{color: 'text.primary', mb: 4}}>
                        Mothercare Junior High School offers the Ghana Education Service Curriculum. Children in our
                        Junior High are active, curious, creative and hard working. We keep them challenged with various
                        activities such as debates, competitions, field trips, excursions and sporting
                        activities. </Typography>

                    <Grid sx={{mb: 4}} container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="01">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Download registration form
                                </Typography>

                                <Typography variant="body1" sx={{color: "text.secondary"}}>
                                    Download registration form here{' '}
                                    <MUILink sx={{color: "secondary.main"}}
                                             href={admissionForm}
                                             download="MCEnrolmentForm2022.docx">
                                        (click now)
                                    </MUILink>{' '}
                                    / collect from the Administrative office here (click now to locate us page).
                                    Download Form
                                </Typography>

                            </Step>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="02">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Submit registration form and collect information pack
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="03">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Attend Readiness Assessment Interview
                                </Typography>
                                <Typography variant="body1" sx={{color: "text.secondary"}}>
                                    Attend an Assessment Interview to evaluate childs’ physical and emotional readiness
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="04">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Receive Offer of Placement letter
                                </Typography>
                                <Typography variant="body1" sx={{color: "text.secondary"}}>
                                    Receive offer of placement letter
                                </Typography>
                            </Step>
                        </Grid>
                    </Grid>
                    <Typography
                        align="center"
                        variant="h6"
                        sx={{color: "secondary.main", textTransform: "uppercase"}}>
                        Placement is then subject to the payment of the Admission Fee and term fee
                    </Typography>
                </Container>
            </Box>

            <Box
                id="steps"
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        Admission into Primary School
                    </Typography>

                    <Typography
                        variant="body2"
                        sx={{color: 'text.primary', mb: 4}}>
                        Mothercare Primary School offers the Ghana Education Service Curriculum, with an international
                        outlook. Children in our Primary are adventurous, active, curious and creative. We keep them
                        motivated and learning with various activities such as debates, competitions, field trips,
                        excursions and sporting activities.
                    </Typography>

                    <Grid sx={{mb: 4}} container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="01">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Download registration form
                                </Typography>

                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Download registration form here{' '}
                                    <MUILink sx={{color: "secondary.main"}}
                                             href={admissionForm}
                                             download="MCEnrolmentForm2022.docx">
                                        (click now)
                                    </MUILink>{' '}
                                    / collect from the Administrative office here (click now to locate us page).
                                    Download Form
                                </Typography>

                            </Step>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="02">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Submit registration form and collect information pack
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="03">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Attend Readiness Assessment Interview
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Attend an Assessment Interview to evaluate childs’ physical and emotional readiness
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="04">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Receive Offer of Placement letter
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Receive offer of placement letter
                                </Typography>
                            </Step>
                        </Grid>
                    </Grid>
                    <Typography
                        align="center"
                        variant="h6"
                        sx={{color: "secondary.main", textTransform: "uppercase"}}>
                        Placement is then subject to the payment of the Admission Fee and term fee
                    </Typography>
                </Container>
            </Box>

            <Box
                id="steps"
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.default'
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        Admission into Pre-school
                    </Typography>


                    <Typography
                        variant="body2"
                        sx={{color: 'text.primary', mb: 4}}>
                        Our preschool caters for children from 6 months to 6 years old. Children may be registered for
                        our preschool anytime from birth, either for enrolment into our Creche (6 months), Nursery 1(2
                        years), Nursery 2 (3 years), Kindergarten 1 (4 years) and Kindergarten 2 (5 years).
                    </Typography>

                    <Grid sx={{mb: 4}} container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="01">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Download registration form
                                </Typography>

                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Download registration form here{' '}
                                    <Link sx={{color: "secondary.main"}}
                                          href={registrationForm}
                                          download="MCEnrolmentForm2022.docx">
                                        (click now)
                                    </Link>{' '}
                                    / collect from the Administrative office here (click now to locate us page).
                                    Download Form
                                </Typography>

                            </Step>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="02">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Submit registration form and collect information pack
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="03">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Attend Readiness Assessment Interview
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Attend an Assessment Interview to evaluate childs’ physical and emotional readiness
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="04">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Receive Offer of Placement letter
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Receive offer of placement letter
                                </Typography>
                            </Step>
                        </Grid>
                    </Grid>
                    <Typography
                        align="center"
                        variant="h6"
                        sx={{color: "secondary.main", textTransform: "uppercase"}}>
                        Placement is then subject to the payment of the Admission Fee and term fee
                    </Typography>
                </Container>
            </Box>
        </Layout>
    )
}

export default DepartmentsPage;
