import {createTheme} from "@mui/material";

const light = createTheme({
    typography: {
        fontFamily: 'EuclidCircularB, GoogleSans,  Aeonik, EuclidCircularA, DM Sans'
    },
    shape: {
        borderRadius: 32
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#ffffff'
        },
        secondary: {
            main: '#7FB9F3'
        },
        background: {
            default: '#f2f4f5',
            paper: '#ffffff'
        },
        text: {
            secondary: '#828395',
            primary: '#2f2f2f'
        },
        light: {
            secondary: 'rgba(127,185,243,0.3)'
        }
    }
});


const dark = createTheme({
    typography: {
        fontFamily: 'EuclidCircularB, GoogleSans, Aeonik, EuclidCircularA, DM Sans'
    },
    shape: {
        borderRadius: 32
    },
    palette: {
        mode: 'dark',
        background: {
            paper: '#212121',
            default: '#323232'
        },
        primary: {
            main: '#212121'
        },
        secondary: {
            main: '#1ac0ea'
        },
        text: {
            primary: '#ffffff',
            secondary: 'rgba(141,141,141,0.7)'
        },
        light: {
            secondary: 'rgba(26,192,234,0.15)'
        }
    },
});

export const THEMES = {light, dark};
