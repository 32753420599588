import Layout from "../../components/layout/layout";
import banner from "../../assets/images/jhsbanner.jpg";
import image1 from "../../assets/images/IMG_6814.jpg";
import DSC_0835 from "../../assets/images/DSC_0835.JPG";

import {Box, Button, CardMedia, Chip, Container, Grid, Link as MUILink, Typography} from "@mui/material";
import {MOTHERCARE_SCHOOL_DATA} from "../../utils/data";
import Feature from "../../components/shared/feature";
import Step from "../../components/shared/step";
import Subject from "../../components/shared/subject";

import admissionForm from "./../../assets/docs/MCEnrolmentForm2022.pdf";
import ImageGallery from "react-image-gallery";

const JHSPage = () => {

    return (<Layout>
        <Box
            sx={{
                position: 'relative',
                pt: 12.5,
                minHeight: {xs: '50vh', lg: '100vh'},
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url('${banner}')`,
                backgroundAttachment: 'scroll',
                backgroundPosition: 'top',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                paddingY: {xs: 4, lg: 0},
                backgroundBlendMode: 'overlay',
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
            }}>
            <Container>
                <Typography align="center" variant="h3" sx={{color: 'white', mb: 4}}>
                    JHS
                </Typography>
                <Typography align="center" variant="h6" sx={{color: 'white', mb: 4}}>
                    Raising global ethical leaders
                </Typography>
            </Container>
        </Box>


        <Box sx={{py: 3, backgroundColor: "secondary.main"}}>
            <Container>
                <Grid
                    container={true}
                    justifyContent="space-between"
                    spacing={2}
                    alignItems="center">
                    <Grid item={true} xs={12} md="auto">
                        <Typography variant="h5" sx={{color: "white"}}>
                            Admissions open for 2023
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} md="auto">
                        <MUILink href="#enrol" sx={{textDecoration: 'none'}}>
                            <Button
                                sx={{
                                    textTransform: 'capitalize'
                                }}
                                disableElevation={true}
                                variant="contained"
                                color="primary"
                                size="large">
                                Enroll Now
                            </Button>
                        </MUILink>
                    </Grid>
                </Grid>
            </Container>
        </Box>


        <Box
            sx={{
                minHeight: '30vh', alignItems: 'center', py: 8, backgroundColor: 'background.paper'
            }}>
            <Container>
                <Grid container={true} spacing={4}>
                    <Grid item={true} xs={12} md={6}>
                        <Typography
                            variant="h5"
                            sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                            Raising Ethical Global Leaders
                        </Typography>

                        <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                            Mothercare Junior High School offers the Ghana Education Service Curriculum and ends with
                            the national examination, Basic Education Certificate Examination (BECE). Children in our
                            Junior High are active, curious, creative and hard working. They have consistently excelled
                            in the BECE, making the school proud. Extra-curricular activities are not left out of their
                            activities. They only enhance the education being given to the students.
                        </Typography>
                    </Grid>

                    <Grid item={true} xs={12} md={6}>
                        <CardMedia
                            component="img"
                            src={image1}
                            sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                    </Grid>
                </Grid>

            </Container>
        </Box>

        <Box
            sx={{
                minHeight: '30vh', alignItems: 'center', py: 8
            }}>
            <Container>
                <Typography
                    variant="h5"
                    sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                    Key Features
                </Typography>
                <Grid container={true} spacing={2}>
                    {MOTHERCARE_SCHOOL_DATA.JHS_KEY_FEATURES.map((feature, index) => {
                        return (<Grid item={true} key={index} xs={12} md={4}>
                            <Feature feature={feature}/>
                        </Grid>)
                    })}
                </Grid>
            </Container>
        </Box>

        <Box
            id="steps"
            sx={{
                minHeight: '30vh', alignItems: 'center', py: 8, backgroundColor: 'background.paper'
            }}>
            <Container>
                <Typography
                    variant="h5"
                    sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                    Enrolment Steps
                </Typography>

                <Typography
                    variant="body1"
                    sx={{color: 'text.primary', mb: 4}}>
                    Our Junior High School caters to children from 11 - 15years old. Note that enrolment into our Junior
                    High School is only permitted into Year 7 (JHS 1). To enroll, you must:
                </Typography>

                <Grid sx={{mb: 4}} container={true} spacing={4}>
                    <Grid item={true} xs={12} md={6}>
                        <Step number="01">
                            <Typography variant="h6" sx={{color: "text.primary"}}>
                                Download Application Form
                            </Typography>
                            <Typography variant="body2" sx={{color: "text.secondary"}}>
                                Download{' '}
                                <MUILink
                                    sx={{color: "secondary.main"}}
                                    href={admissionForm}
                                    download="MCEnrolmentForm2022.docx">
                                    Application Form
                                </MUILink>{' '}
                                / collect from the
                                <MUILink
                                    sx={{color: "secondary.main"}}
                                    href={"https://goo.gl/maps/3FszttUjMoarMoiu8"}>
                                    school office
                                </MUILink>.
                            </Typography>

                        </Step>
                    </Grid>

                    <Grid item={true} xs={12} md={6}>
                        <Step number="02">
                            <Typography variant="h6" sx={{color: "text.primary", mb: 1}}>
                                Attend Readiness Assessment Interview
                            </Typography>
                            <Typography variant="body2" sx={{color: "text.secondary", mb: 1}}>
                                This includes an entrance examination (Maths, English and/or Science) and an interview
                                with one of the head teachers.
                            </Typography>
                            <Typography variant="body2" sx={{color: "text.secondary"}}>
                                (a) Note that there is a small fee covering the entrance examination.
                            </Typography>
                            <Typography variant="body2" sx={{color: "text.secondary", mb: 1}}>
                                (b) Examinations may be taken on various dates between May and September on the
                                school premises.
                            </Typography>
                            <Typography variant="body2" sx={{color: "text.secondary"}}>
                                (c) Students will only be granted admission if there are vacancies in the class they
                                require.
                            </Typography>
                        </Step>
                    </Grid>

                    <Grid item={true} xs={12} md={6}>
                        <Step number="03">
                            <Typography variant="h6" sx={{color: "text.primary"}}>
                                <span style={{fontWeight: "bold"}}>Submit Application Form</span> upon approval of
                                Head teachers, along with the following :
                            </Typography>
                            <Typography variant="body2" sx={{color: "text.secondary"}}>
                                1. Previous reports/Cumulative Records
                            </Typography>
                            <Typography variant="body2" sx={{color: "text.secondary"}}>
                                2. Student’s birth certificate
                            </Typography>
                            <Typography variant="body2" sx={{color: "text.secondary"}}>
                                3. Two passport sized pictures
                            </Typography>
                        </Step>
                    </Grid>

                    <Grid item={true} xs={12} md={6}>
                        <Step number="04">
                            <Typography variant="h6" sx={{color: "text.primary"}}>
                                <span style={{fontWeight: "bold"}}>Receive Offer of Admission</span> after full payment
                                of fees has been made.
                            </Typography>
                        </Step>
                    </Grid>
                </Grid>
            </Container>
        </Box>

        <Box
            sx={{
                minHeight: '30vh', alignItems: 'center', py: 8
            }}>
            <Container>
                <Typography
                    variant="h5"
                    sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                    ACADEMIC SUBJECTS (YEAR 7-9/JHS 1-3)
                </Typography>
                <Grid container={true} spacing={2}>
                    {MOTHERCARE_SCHOOL_DATA.JHS_SUBJECTS.map((subject, index) => {
                        return (<Grid item={true} key={index} xs={12} md={4}>
                            <Subject subject={subject}/>
                        </Grid>)
                    })}
                </Grid>
            </Container>
        </Box>

        <Box
            sx={{
                minHeight: '30vh', alignItems: 'center', py: 8, backgroundColor: 'background.paper'
            }}>
            <Container>
                <Typography
                    variant="h5"
                    sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                    Co-Curricular Activities
                </Typography>

                <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                    As part of our commitment to producing
                    well-rounded students, Mothercare school hosts a rich co-curricular activity program to increase
                    your child's exposure to various interests, careers, and people. All activities within this
                    programme are carefully designed to
                    contribute to your childs’ leadership, socio-emotional, and inter-personal development.
                </Typography>

                <Grid container={true} spacing={4}>
                    <Grid item={true} xs={12} md={6}>
                        <Typography
                            variant="h6"
                            sx={{color: 'text.primary', mb: 4}}>
                            Schools Clubs and After-school Program
                        </Typography>

                        <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                            Students may participate in a variety of activities including Sports, creative arts,
                            media and writing, and STEM-related activities
                        </Typography>

                        <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                            For example
                        </Typography>

                        <Grid container={true} spacing={2}>
                            {["Choreography", "Soccer", "Swimming", "Ballet", "African Accessory Design", "Drama", "Music", "Magazine club", "Coding", "Robotics"].map((example, index) => {
                                return (<Grid key={index} item={true}>
                                    <Chip label={example}/>
                                </Grid>)
                            })}
                        </Grid>
                    </Grid>

                    <Grid item={true} xs={12} md={6}>
                        <CardMedia
                            component="img"
                            src={DSC_0835}
                            sx={{height: '100%', width: '100%', borderRadius: '5%'}}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>

        <Box sx={{py: 8}}>
            <Container maxWidth="xl">
                <Typography variant="h4" align="center" sx={{color: "secondary.main", mb: 4}}>
                    Gallery
                </Typography>
            </Container>
            <ImageGallery
                showPlayButton={false}
                autoPlay={true}
                slideDuration={300}
                showThumbnails={false}
                items={MOTHERCARE_SCHOOL_DATA.GALLERY.JHS}
            />
        </Box>
    </Layout>)
}

export default JHSPage;
