import {Card, CardContent, Stack, Typography} from "@mui/material";

const Subject = ({subject}) => {
    return (
        <Card elevation={0} sx={{
            height: '100%',
            backgroundColor: "light.secondary",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 32,
            borderTopLeftRadius: 32,
            borderTopRightRadius: 0
        }}>
            <CardContent>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" justifyContent="center">
                        <img src={subject.image} alt="" style={{height: 150, width: 150}}/>
                    </Stack>
                    <Typography align="center" variant="h6" sx={{color: "text.primary"}}>
                        {subject.name}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Subject;
