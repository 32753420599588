import Layout from "../../components/layout/layout";
import banner from "../../assets/images/DEPARTMENT-PRIMARY-Replace Web-banner.jpg";
import first from "../../assets/images/DEPARTMENT-PRIMARY-First story photo.jpg";
import curricular from "../../assets/images/DEPARTMENT-PRIMARY-CO CURRICULAR ACTIVITY IMAGE.jpg";
import {Box, Button, CardMedia, Chip, Container, Grid, Link as MUILink, Link, Typography} from "@mui/material";
import {MOTHERCARE_SCHOOL_DATA} from "../../utils/data";
import Feature from "../../components/shared/feature";
import Step from "../../components/shared/step";
import Subject from "../../components/shared/subject";
import ImageGallery from "react-image-gallery";
import admissionForm from "../../assets/docs/MCEnrolmentForm2022.pdf";

const PrimarySchoolPage = () => {

    return (
        <Layout>
            <Box
                sx={{
                    position: 'relative',
                    pt: 12.5,
                    minHeight: {xs: '50vh', lg: '100vh'},
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('${banner}')`,
                    backgroundAttachment: 'scroll',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingY: {xs: 4, lg: 0},
                    backgroundBlendMode: 'overlay',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}>
                <Container>
                    <Typography align="center" variant="h3" sx={{color: 'white', mb: 4}}>
                        Primary
                    </Typography>
                    <Typography align="center" variant="h6" sx={{color: 'white', mb: 4}}>
                        Raising ethical global leaders
                    </Typography>
                </Container>
            </Box>


            <Box sx={{py: 3, backgroundColor: "secondary.main"}}>
                <Container>
                    <Grid
                        container={true}
                        justifyContent="space-between"
                        spacing={2}
                        alignItems="center">
                        <Grid item={true} xs={12} md="auto">
                            <Typography variant="h5" sx={{color: "white"}}>
                                Admissions open for 2023
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} md="auto">
                            <Link href="#steps" sx={{textDecoration: 'none'}}>
                                <Button
                                    sx={{
                                        textTransform: 'capitalize'
                                    }}
                                    disableElevation={true}
                                    variant="contained"
                                    color="primary"
                                    size="large">
                                    Enroll Now
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container>
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                Raising Ethical Global Leaders
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.primary', mb: 2}}>
                                Mothercare Primary School offers the Ghana Education Service Curriculum, with an
                                international outlook. Children in our Primary are adventurous, active, curious and
                                creative. We keep them motivated and learning with various activities such as debates,
                                competitions, field trips, excursions and sporting activities. They are quickly made
                                familiar with various activities such as debates, competitions, field trips, excursions
                                and sporting activities.
                            </Typography>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={first}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                        Key Features
                    </Typography>
                    <Grid container={true} spacing={2}>
                        {MOTHERCARE_SCHOOL_DATA.PRIMARY_SCHOOL_KEY_FEATURES.map((feature, index) => {
                            return (
                                <Grid item={true} key={index} xs={12} md={4}>
                                    <Feature feature={feature}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>

            <Box
                id="steps"
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        Enrolment Steps
                    </Typography>

                    <Typography
                        variant="body2"
                        sx={{color: 'text.primary', mb: 4}}>
                        Our primary school caters to children from 6 - 11 years old.  To enroll, you must:
                    </Typography>

                    <Grid sx={{mb: 4}} container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="01">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Download Application Form
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Download{' '}
                                    <MUILink
                                        sx={{color: "secondary.main"}}
                                        href={admissionForm}
                                        download="MCEnrolmentForm2022.docx">
                                        Application Form
                                    </MUILink>{' '}
                                    / collect from the
                                    <MUILink
                                        sx={{color: "secondary.main"}}
                                        href={"https://goo.gl/maps/3FszttUjMoarMoiu8"}>
                                        school office
                                    </MUILink>.
                                </Typography>

                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="02">
                                <Typography variant="h6" sx={{color: "text.primary", mb: 1}}>
                                    Attend Readiness Assessment Interview
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 1}}>
                                    This includes an entrance examination (Maths, English and/or Science) and an interview with one of the head teachers.
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    (a) Note that there is a small fee covering the entrance examination.
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 1}}>
                                    (b) Examinations may be taken on various dates between May and September on the
                                    school premises.
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    (c) Students will only be granted admission if there are vacancies in the class they
                                    require.
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="03">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    <span style={{fontWeight: "bold"}}>Submit Application Form</span> upon approval of
                                    Head teachers, along with the following :
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    1.	Previous reports/Cumulative Records
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    2.	Student’s birth certificate
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    3.	Two passport sized pictures
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="04">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    <span style={{fontWeight: "bold"}}>Receive Offer of Admission</span>  after full payment of fees has been made.
                                </Typography>
                            </Step>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                        Academic Subjects (Year 7 - 9)
                    </Typography>
                    <Grid container={true} spacing={2}>
                        {MOTHERCARE_SCHOOL_DATA.PRIMARY_SUBJECTS.map((subject, index) => {
                            return (
                                <Grid item={true} key={index} xs={12} md={4}>
                                    <Subject subject={subject}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                        Co-Curricular Activities
                    </Typography>

                    <Typography variant="body2" sx={{color: 'text.primary', mb: 2}}>
                        As part of our commitment to producing well-rounded students, Alpha Beta hosts a rich
                        co-curricular activity programme to increase your child's exposure to various interests,
                        careers and people. All activities within this programme are carefully designed to
                        contribute to your childs’ leadership, socio-emotional, and inter-personal development.
                    </Typography>

                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h6"
                                sx={{color: 'text.primary', mb: 4}}>
                                Schools Clubs and After-school Program
                            </Typography>

                            <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                                Students may participate in a variety of activities including Sports, creative arts,
                                media and writing, and STEM-related activities
                            </Typography>

                            <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                                For example
                            </Typography>
                            <Grid container={true} spacing={2}>
                                {["Choreography", "Soccer", "Swimming", "Ballet", "African Accessory Design", "Drama", "Music", "Magazine club", "Coding", "Robotics"].map((example, index) => {
                                    return (
                                        <Grid item={true}>
                                            <Chip label={example}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={curricular}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                    </Grid>


                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h6"
                                sx={{color: 'text.primary', mb: 4}}>
                                Local and International Trips
                            </Typography>

                            <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                                Local and international excursions are organized regularly to allow our students to
                                experience practical aspects of the academic curriculum and gain local and international
                                exposure.
                            </Typography>
                            <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                                In addition to visits across Ghana, countries our students have visited other countries
                                including the USA, UK, France, Italy, South Africa, Togo, Burkina Faso and Ivory
                                Coast
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={banner}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{py: 8}}>
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main", mb: 4}}>
                        Gallery
                    </Typography>
                </Container>
                <ImageGallery
                    showPlayButton={false}
                    autoPlay={true}
                    slideDuration={300}
                    showThumbnails={false}
                    items={MOTHERCARE_SCHOOL_DATA.GALLERY.PRIMARY}
                />
            </Box>
        </Layout>
    )
}

export default PrimarySchoolPage;
