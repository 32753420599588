import Layout from "../../components/layout/layout";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Link as MUILink,
    Stack,
    Typography
} from "@mui/material";
import director from "./../../assets/images/1. Sheila Acquah-Asare - School Director .jpg";
import preschool from "./../../assets/images/preschool.jpg";
import primary from "./../../assets/images/primary.png";
import jhs from "./../../assets/images/jhs.jpg";
import alumni from "./../../assets/images/alumni.jpg";
import internationalJHS from "./../../assets/images/internationalJHS.jpg";

import {Link} from "react-router-dom";
import openHouse from "../../assets/images/MCS Open House .jpg";
import sixthForm from "../../assets/images/SIXTH FORM.jpg";
import graduates from "../../assets/images/BECE GRADUATES CONG.jpg";
import form4 from "../../assets/images/FORM 4 ADMISSIONS.jpg";
import form from "../../assets/images/FORM 1.jpg";
import openHouse1 from "../../assets/images/OPEN HOUSE.jpg";
import memorialDay from "../../assets/images/MEMORIAL DAY.jpg";
import mentalHealth from "../../assets/images/MENTAL HEALTH.jpg";
import mentalHealth2022 from "../../assets/images/MENTAL HEALTH 2022.jpg";
import socialMedia from "../../assets/images/SOCIAL MEDIA.jpg";
import teachersDay from "../../assets/images/TEACHERS DAY.jpg";
import hiringIT from "../../assets/images/HIRING.jpg";
import hiringEnglish from "../../assets/images/HIRING ENGLISH.jpg";
import backToSchool from "../../assets/images/BACK TO SCHOOL.jpg";
import backToSchoolPrimary from "../../assets/images/BACK TO SCHOOL PRIMRY.jpg";
import NukaCarousel from "nuka-carousel";
import Banner from "../../components/shared/banner";
import placeholder from "./../../assets/images/placeholder.jpg";

import preschoolBanner from "./../../assets/images/banners/IMG_6850.jpg";
import primaryBanner from "./../../assets/images/banners/IMG_6492.jpg";
import jhsBanner from "./../../assets/images/banners/IMG_6776.jpg";
import internationalSecondaryBanner from "./../../assets/images/banners/IMG_6593.jpg";

import christmas from "./../../assets/images/christmas.jpg";
import newYear from "./../../assets/images/newyear.jpg";
import backToSchool1 from "./../../assets/images/back-to-school-1.jpg";
import backToSchool2 from "./../../assets/images/back-to-school-2.jpg";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import {MOTHERCARE_SCHOOL_DATA} from "../../utils/data";
import ProgressiveImage from "react-progressive-image";

const HomePage = () => {

    // const {facebookLoading, facebookPosts} = useSelector(selectFacebook);
    // const {articlesLoading, articles} = useSelector(selectArticles);
    // const {twitterLoading, tweets} = useSelector(selectTweets);
    // const {instagramLoading, instagramPosts} = useSelector(selectInstagram);

    return (
        <Layout>
            <Box>
                <NukaCarousel
                    withoutControls={true}
                    wrapAround={true}
                    swiping={false}
                    cellAlign="center"
                    pauseOnHover={true}
                    dragging={true}
                    autoplay={true}
                    speed={2000}
                    zoomScale={1}
                    enableKeyboardControls={true}
                    slidesToShow={1}
                    animation="zoom">
                    <Box
                        sx={{
                            height: {xs: '50vh', lg: '100vh'},
                            width: '100vw'
                        }}>
                        <Banner
                            image={
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        objectPosition: 'top'
                                    }}
                                    alt=""
                                    src={preschoolBanner}
                                />
                            }
                            children={
                                <Box>
                                    <Container maxWidth="xl">
                                        <Stack direction="column" spacing={3}>
                                            <Typography
                                                align="center"
                                                sx={{color: 'secondary.main'}}
                                                variant="h3">
                                                Pre-school
                                            </Typography>
                                            <Typography
                                                sx={{color: 'white', textTransform: "capitalize"}}
                                                align="center"
                                                variant="h6">
                                                Transforming Lives
                                            </Typography>

                                            <Stack direction="row" justifyContent="center">
                                                <Link
                                                    style={{textDecoration: 'none'}}
                                                    to="/departments/preschool">
                                                    <Button
                                                        disableElevation={true}
                                                        sx={{
                                                            borderBottomLeftRadius: 0,
                                                            borderBottomRightRadius: 16,
                                                            borderTopLeftRadius: 16,
                                                            borderTopRightRadius: 0,
                                                            textTransform: 'capitalize'
                                                        }}
                                                        variant="contained"
                                                        size="large">
                                                        Learn More
                                                    </Button>
                                                </Link>
                                            </Stack>
                                        </Stack>
                                    </Container>
                                </Box>
                            }/>
                    </Box>
                    <Box
                        sx={{
                            height: {xs: '50vh', lg: '100vh'},
                            width: '100vw'
                        }}>
                        <Banner
                            image={
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        objectPosition: 'top'
                                    }}
                                    alt=""
                                    src={primaryBanner}
                                />
                            }
                            children={
                                <Box>
                                    <Container maxWidth="xl">
                                        <Stack direction="column" spacing={3}>
                                            <Typography
                                                align="center"
                                                sx={{color: 'secondary.main'}}
                                                variant="h3">
                                                Primary School
                                            </Typography>
                                            <Typography
                                                sx={{color: 'white'}}
                                                align="center"
                                                variant="h6">
                                                Raising Ethical Global Leaders
                                            </Typography>
                                            <Stack direction="row" justifyContent="center">
                                                <Link
                                                    style={{textDecoration: "none"}}
                                                    to="/departments/primary">
                                                    <Button
                                                        disableElevation={true}
                                                        sx={{
                                                            borderBottomLeftRadius: 0,
                                                            borderBottomRightRadius: 16,
                                                            borderTopLeftRadius: 16,
                                                            borderTopRightRadius: 0,
                                                            textTransform: 'capitalize'
                                                        }}
                                                        variant="contained"
                                                        size="large">
                                                        Learn More
                                                    </Button>
                                                </Link>
                                            </Stack>
                                        </Stack>
                                    </Container>
                                </Box>
                            }/>
                    </Box>
                    <Box
                        sx={{
                            height: {xs: '50vh', lg: '100vh'},
                            width: '100vw'
                        }}>
                        <Banner
                            image={
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        objectPosition: 'top'
                                    }}
                                    alt=""
                                    src={jhsBanner}
                                />
                            }
                            children={
                                <Box>
                                    <Container maxWidth="xl">
                                        <Stack direction="column" spacing={3}>
                                            <Typography
                                                align="center"
                                                sx={{color: 'secondary.main'}}
                                                variant="h3">
                                                JHS
                                            </Typography>
                                            <Typography
                                                sx={{color: 'white', textTransform: "capitalize"}}
                                                align="center"
                                                variant="h6">
                                                Preparing Your Child For An Outstanding Life!
                                            </Typography>

                                            <Stack direction="row" justifyContent="center">
                                                <Link
                                                    style={{textDecoration: 'none'}}
                                                    to="/departments/jhs">
                                                    <Button
                                                        disableElevation={true}
                                                        sx={{
                                                            borderBottomLeftRadius: 0,
                                                            borderBottomRightRadius: 16,
                                                            borderTopLeftRadius: 16,
                                                            borderTopRightRadius: 0,
                                                            textTransform: 'capitalize'
                                                        }}
                                                        variant="contained"
                                                        size="large">
                                                        Learn More
                                                    </Button>
                                                </Link>
                                            </Stack>
                                        </Stack>
                                    </Container>
                                </Box>
                            }/>
                    </Box>
                    <Box
                        sx={{
                            height: {xs: '50vh', lg: '100vh'},
                            width: '100vw'
                        }}>
                        <Banner
                            image={
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        objectPosition: 'top',
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 16,
                                        borderTopLeftRadius: 16,
                                        borderTopRightRadius: 0,
                                    }}
                                    alt=""
                                    src={internationalSecondaryBanner}
                                />
                            }
                            children={
                                <Box>
                                    <Container maxWidth="xl">
                                        <Stack direction="column" spacing={3}>
                                            <Typography
                                                align="center"
                                                sx={{color: 'secondary.main'}}
                                                variant="h3">
                                                International Secondary
                                            </Typography>
                                            <Typography
                                                sx={{color: 'white', textTransform: "capitalize"}}
                                                align="center"
                                                variant="h6">
                                                Join The Mothercare Family Today!
                                            </Typography>

                                            <Stack direction="row" justifyContent="center">
                                                <Link
                                                    style={{textDecoration: 'none'}}
                                                    to="/departments/international">
                                                    <Button
                                                        disableElevation={true}
                                                        sx={{
                                                            borderBottomLeftRadius: 0,
                                                            borderBottomRightRadius: 16,
                                                            borderTopLeftRadius: 16,
                                                            borderTopRightRadius: 0,
                                                            textTransform: 'capitalize'
                                                        }}
                                                        variant="contained"
                                                        size="large">
                                                        Learn More
                                                    </Button>
                                                </Link>
                                            </Stack>
                                        </Stack>
                                    </Container>
                                </Box>
                            }/>
                    </Box>
                </NukaCarousel>
            </Box>

            <Box sx={{py: 3, backgroundColor: "secondary.main"}}>
                <Container maxWidth="xl">
                    <Grid
                        container={true}
                        justifyContent="space-between"
                        spacing={2}
                        alignItems="center">
                        <Grid item={true} xs={12} md="auto">
                            <Typography variant="h5" sx={{color: "white", textAlign: {xs: "center", lg: "left"}}}>
                                Admissions open for 2023
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} md="auto">
                            <Stack direction="row" justifyContent="center">
                                <Link to="/departments" style={{textDecoration: 'none'}}>
                                    <Button
                                        sx={{
                                            textTransform: 'capitalize',
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 16,
                                            borderTopLeftRadius: 16,
                                            borderTopRightRadius: 0,
                                        }}
                                        disableElevation={true}
                                        variant="contained"
                                        color="primary"
                                        size="large">
                                        Enroll Now
                                    </Button>
                                </Link>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase', fontSize:{xs: 22, lg: 32}}}>
                                WELCOME TO MOTHERCARE
                            </Typography>

                            <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                                At Mothercare School, we believe in providing high quality, outstanding value education
                                in a Christian and challenging learning environment. This is supported in our motto
                                (Proverbs 22 verse 6) which says “Train up a child in the way he should go: and when he
                                is old, he will not depart from it.”
                            </Typography>

                            <Grid item={true} xs={12} md="auto">
                                <Link to="/about" style={{textDecoration: 'none'}}>
                                    <Button
                                        sx={{
                                            textTransform: 'capitalize',
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 16,
                                            borderTopLeftRadius: 16,
                                            borderTopRightRadius: 0,
                                        }}
                                        disableElevation={true}
                                        variant="contained"
                                        color="secondary"
                                        size="large">
                                        Read More
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <ProgressiveImage placeholder={placeholder} src={director}>
                                {(src, loading) => (
                                    <CardMedia
                                        component="img"
                                        src={director}
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 16,
                                            borderTopLeftRadius: 16,
                                            borderTopRightRadius: 0,
                                            opacity: loading ? 0.5 : 1
                                        }}
                                    />
                                )}
                            </ProgressiveImage>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8
                }}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={3}>
                            <Card
                                elevation={0}
                                variant="outlined"
                                sx={{
                                    position: 'relative',
                                    pt: 12.5,
                                    height: 300,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundImage: `url('${preschool}')`,
                                    backgroundAttachment: 'scroll',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    paddingY: {xs: 4, lg: 0},
                                    backgroundBlendMode: 'multiply',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 16,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 0,
                                }}>
                                <CardContent>
                                    <Typography variant="h4" align="center" sx={{color: 'white', mb: 4}}>
                                        Pre-school
                                    </Typography>
                                    <Stack direction="row" justifyContent="center">
                                        <Link to='/departments/preschool' style={{textDecoration: 'none'}}>
                                            <Button
                                                size="large"
                                                variant="contained"
                                                disableElevation={true}
                                                color="secondary"
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    opacity: 0.8,
                                                    color: "white",
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 16,
                                                    borderTopLeftRadius: 16,
                                                    borderTopRightRadius: 0,
                                                }}>
                                                Learn More
                                            </Button>
                                        </Link>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item={true} xs={12} md={3}>
                            <Card
                                elevation={0}
                                variant="outlined"
                                sx={{
                                    position: 'relative',
                                    pt: 12.5,
                                    height: 300,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundImage: `url('${primary}')`,
                                    backgroundAttachment: 'scroll',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    paddingY: {xs: 4, lg: 0},
                                    backgroundBlendMode: 'multiply',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 16,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 0,
                                }}>
                                <CardContent>
                                    <Typography variant="h4" align="center" sx={{color: 'white', mb: 4}}>
                                        Primary
                                    </Typography>
                                    <Stack direction="row" justifyContent="center">
                                    <Link to='/departments/primary' style={{textDecoration: 'none'}}>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            disableElevation={true}
                                            color="secondary"
                                            sx={{
                                                textTransform: 'capitalize',
                                                opacity: 0.8,
                                                color: "white",
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 16,
                                                borderTopLeftRadius: 16,
                                                borderTopRightRadius: 0,
                                            }}>
                                            Learn More
                                        </Button>
                                    </Link>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item={true} xs={12} md={3}>
                            <Card
                                elevation={0}
                                variant="outlined"
                                sx={{
                                    position: 'relative',
                                    pt: 12.5,
                                    height: 300,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundImage: `url('${jhs}')`,
                                    backgroundAttachment: 'scroll',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    paddingY: {xs: 4, lg: 0},
                                    backgroundBlendMode: 'multiply',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 16,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 0,
                                }}>
                                <CardContent>
                                    <Typography variant="h4" align="center" sx={{color: 'white', mb: 4}}>
                                        JHS
                                    </Typography>
                                    <Stack direction="row" justifyContent="center">
                                        <Link to='/departments/jhs' style={{textDecoration: 'none'}}>
                                            <Button
                                                size="large"
                                                variant="contained"
                                                disableElevation={true}
                                                color="secondary"
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    opacity: 0.8,
                                                    color: "white",
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 16,
                                                    borderTopLeftRadius: 16,
                                                    borderTopRightRadius: 0,
                                                }}>
                                                Learn More
                                            </Button>
                                        </Link>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item={true} xs={12} md={3}>
                            <Card
                                elevation={0}
                                variant="outlined"
                                sx={{
                                    position: 'relative',
                                    pt: 12.5,
                                    height: 300,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundImage: `url('${internationalJHS}')`,
                                    backgroundAttachment: 'scroll',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    paddingY: {xs: 4, lg: 0},
                                    backgroundBlendMode: 'multiply',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 16,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 0,
                                }}>
                                <CardContent>
                                    <Typography variant="h4" align="center" sx={{color: 'white', mb: 4}}>
                                        International Secondary
                                    </Typography>
                                    <Stack direction="row" justifyContent="center">
                                        <Link to='/departments/international' style={{textDecoration: 'none'}}>
                                            <Button
                                                size="large"
                                                variant="contained"
                                                disableElevation={true}
                                                color="secondary"
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    opacity: 0.8,
                                                    color: "white",
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 16,
                                                    borderTopLeftRadius: 16,
                                                    borderTopRightRadius: 0,
                                                }}>
                                                Learn More
                                            </Button>
                                        </Link>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    position: 'relative',
                    pt: 12.5,
                    height: {xs: '40vh', lg: '100vh'},
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('${alumni}')`,
                    backgroundAttachment: 'scroll',
                    backgroundPosition: 'top',
                    backgroundSize: {xs: 'cover', lg: 'cover'},
                    backgroundRepeat: 'no-repeat',
                    paddingY: {xs: 4, lg: 0},
                    backgroundBlendMode: 'overlay',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 16,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 0,
                }}>
                <Container maxWidth="xl">
                    <Grid
                        container={true}
                        justifyContent="space-between"
                        spacing={4}
                        alignItems="center">
                        <Grid item={true} xs={12} md="auto">
                            <Typography variant="h3" sx={{color: "white", fontSize: {xs: 24, lg: 48}}}>
                                Join our alumni network
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} md="auto">
                            <MUILink
                                target="_blank"
                                underline="none"
                                href="https://forms.gle/FJ4MuWy3CpXmKwgP7"
                                download="Join Mother Care School Alumni" style={{textDecoration: 'none'}}>
                                <Button
                                    sx={{
                                        textTransform: 'capitalize',
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 16,
                                        borderTopLeftRadius: 16,
                                        borderTopRightRadius: 0,
                                    }}
                                    fullWidth={true}
                                    disableElevation={true}
                                    variant="contained"
                                    color="secondary"
                                    size="large">
                                    Register
                                </Button>
                            </MUILink>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.paper"}}>
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main", mb: 2}}>
                        Community News
                    </Typography>
                    <Typography variant="h6" align="center" sx={{color: "text.primary", mb: 4}}>
                        Admission Campaigns
                    </Typography>

                    <Grid container={true} spacing={4}>


                        <Grid item={true} xs={12} md={6} lg={3}>
                            <CardMedia
                                component="img"
                                src={backToSchool1}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={backToSchool2}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={newYear}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={christmas}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>

                        <Grid item={true} xs={12} md={6} lg={3}>
                            <CardMedia
                                component="img"
                                src={openHouse}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={sixthForm}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={graduates}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={form4}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={form}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={openHouse1}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.default"}}>
                <Container maxWidth="xl">
                    <Typography variant="h6" align="center" sx={{color: "text.primary", mb: 4}}>
                        Community Update
                    </Typography>

                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={memorialDay}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={mentalHealth}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={mentalHealth2022}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={socialMedia}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={teachersDay}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid> <Grid item={true} xs={12} md={4} lg={3}>
                        <CardMedia
                            component="img"
                            src={hiringIT}
                            sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                    </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={hiringEnglish}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={backToSchool}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={backToSchoolPrimary}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{py: 8}}>
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main", mb: 4}}>
                        Gallery
                    </Typography>
                </Container>
                <ImageGallery
                    showPlayButton={false}
                    autoPlay={true}
                    slideDuration={300}
                    showThumbnails={false}
                    items={MOTHERCARE_SCHOOL_DATA.GALLERY.HOME}
                />
            </Box>

            {/*<Box sx={{py: 8, backgroundColor: "background.paper"}}>*/}
            {/*    {facebookLoading && <LinearProgress variant="determinate" color="secondary"/>}*/}
            {/*    <Container maxWidth="xl">*/}
            {/*        <Typography variant="h4" align="center" sx={{color: "secondary.main"}}>*/}
            {/*            Latest Facebook Posts*/}
            {/*        </Typography>*/}

            {/*        <Box sx={{py: 2}}>*/}
            {/*            {facebookPosts?.length === 0 ? (*/}
            {/*                <Box>*/}
            {/*                    <Empty*/}
            {/*                        icon={<img style={{height: 200, width: 200}} src={notFound} alt=""/>}*/}
            {/*                        title="No posts available"*/}
            {/*                    />*/}
            {/*                </Box>*/}
            {/*            ) : (*/}
            {/*                <Carousel*/}
            {/*                    autoPlay={true}*/}
            {/*                    autoPlaySpeed={5000}*/}
            {/*                    infinite={true}*/}
            {/*                    partialVisible={true}*/}
            {/*                    keyBoardControl={true}*/}
            {/*                    pauseOnHover={true}*/}
            {/*                    responsive={UTILS.responsive}>{facebookPosts?.map((post, index) => {*/}
            {/*                    return (*/}
            {/*                        <Box key={index}>*/}
            {/*                            <FacebookPost post={post}/>*/}
            {/*                        </Box>*/}
            {/*                    )*/}
            {/*                })}*/}
            {/*                </Carousel>*/}
            {/*            )}*/}
            {/*        </Box>*/}

            {/*        <Stack direction="row" justifyContent="flex-end">*/}
            {/*            <Link to="/news/facebook" style={{textDecoration: "none"}}>*/}
            {/*                <Button*/}
            {/*                    color="secondary"*/}
            {/*                    variant="text"*/}
            {/*                    endIcon={<KeyboardArrowRight color="secondary"/>}*/}
            {/*                    sx={{textTransform: "capitalize"}}>*/}
            {/*                    All Facebook Posts*/}
            {/*                </Button>*/}
            {/*            </Link>*/}
            {/*        </Stack>*/}
            {/*    </Container>*/}
            {/*</Box>*/}

            {/*<Box sx={{py: 8, backgroundColor: "background.default"}}>*/}
            {/*    {twitterLoading && <LinearProgress variant="determinate" color="secondary"/>}*/}
            {/*    <Container maxWidth="xl">*/}
            {/*        <Typography variant="h4" align="center" sx={{color: "secondary.main"}}>*/}
            {/*            Latest Tweets*/}
            {/*        </Typography>*/}

            {/*        <Box sx={{py: 2}}>*/}
            {/*            {tweets?.length === 0 ? (*/}
            {/*                <Box>*/}
            {/*                    <Empty*/}
            {/*                        icon={<img style={{height: 200, width: 200}} src={notFound} alt=""/>}*/}
            {/*                        title="No tweets available"*/}
            {/*                    />*/}
            {/*                </Box>*/}
            {/*            ) : (*/}
            {/*                <Carousel*/}
            {/*                    autoPlay={true}*/}
            {/*                    autoPlaySpeed={5000}*/}
            {/*                    infinite={true}*/}
            {/*                    partialVisible={true}*/}
            {/*                    keyBoardControl={true}*/}
            {/*                    pauseOnHover={true}*/}
            {/*                    responsive={UTILS.responsive}>{tweets?.map((tweet, index) => {*/}
            {/*                    return (*/}
            {/*                        <Box key={index}>*/}
            {/*                            <TwitterFeed feed={tweet}/>*/}
            {/*                        </Box>*/}
            {/*                    )*/}
            {/*                })}*/}
            {/*                </Carousel>*/}
            {/*            )}*/}
            {/*        </Box>*/}

            {/*        <Stack direction="row" justifyContent="flex-end">*/}
            {/*            <Link to="/news/twitter" style={{textDecoration: "none"}}>*/}
            {/*                <Button*/}
            {/*                    color="secondary"*/}
            {/*                    variant="text"*/}
            {/*                    endIcon={<KeyboardArrowRight color="secondary"/>}*/}
            {/*                    sx={{textTransform: "capitalize"}}>*/}
            {/*                    All Tweets*/}
            {/*                </Button>*/}
            {/*            </Link>*/}
            {/*        </Stack>*/}
            {/*    </Container>*/}
            {/*</Box>*/}

            {/*<Box sx={{py: 8, backgroundColor: "background.paper"}}>*/}
            {/*    {instagramLoading && <LinearProgress variant="determinate" color="secondary"/>}*/}
            {/*    <Container maxWidth="xl">*/}
            {/*        <Typography variant="h4" align="center" sx={{color: "secondary.main"}}>*/}
            {/*            Latest Instagram Posts*/}
            {/*        </Typography>*/}

            {/*        <Box sx={{py: 2}}>*/}
            {/*            {instagramPosts?.length === 0 ? (*/}
            {/*                <Box>*/}
            {/*                    <Empty*/}
            {/*                        icon={<img style={{height: 200, width: 200}} src={notFound} alt=""/>}*/}
            {/*                        title="No posts available"*/}
            {/*                    />*/}
            {/*                </Box>*/}
            {/*            ) : (*/}
            {/*                <Carousel*/}
            {/*                    autoPlay={true}*/}
            {/*                    autoPlaySpeed={5000}*/}
            {/*                    infinite={true}*/}
            {/*                    partialVisible={true}*/}
            {/*                    keyBoardControl={true}*/}
            {/*                    pauseOnHover={true}*/}
            {/*                    responsive={UTILS.responsive}>{instagramPosts?.map((post, index) => {*/}
            {/*                    return (*/}
            {/*                        <Box key={index}>*/}
            {/*                            <InstagramPost post={post}/>*/}
            {/*                        </Box>*/}
            {/*                    )*/}
            {/*                })}*/}
            {/*                </Carousel>*/}
            {/*            )}*/}
            {/*        </Box>*/}

            {/*        <Stack direction="row" justifyContent="flex-end">*/}
            {/*            <Link to="/news/instagram" style={{textDecoration: "none"}}>*/}
            {/*                <Button*/}
            {/*                    color="secondary"*/}
            {/*                    variant="text"*/}
            {/*                    endIcon={<KeyboardArrowRight color="secondary"/>}*/}
            {/*                    sx={{textTransform: "capitalize"}}>*/}
            {/*                    All Posts*/}
            {/*                </Button>*/}
            {/*            </Link>*/}
            {/*        </Stack>*/}
            {/*    </Container>*/}
            {/*</Box>*/}

            {/*<Box sx={{py: 8, backgroundColor: "background.default"}}>*/}
            {/*    {articlesLoading && <LinearProgress variant="determinate" color="secondary"/>}*/}
            {/*    <Container maxWidth="xl">*/}
            {/*        <Typography variant="h4" align="center" sx={{color: "secondary.main"}}>*/}
            {/*            Latest Articles*/}
            {/*        </Typography>*/}

            {/*        <Box sx={{py: 2}}>*/}
            {/*            {articles?.length === 0 ? (*/}
            {/*                <Box>*/}
            {/*                    <Empty*/}
            {/*                        icon={<img style={{height: 200, width: 200}} src={notFound} alt=""/>}*/}
            {/*                        title="No articles available"*/}
            {/*                    />*/}
            {/*                </Box>*/}
            {/*            ) : (*/}
            {/*                <Carousel*/}
            {/*                    autoPlay={true}*/}
            {/*                    autoPlaySpeed={5000}*/}
            {/*                    infinite={true}*/}
            {/*                    partialVisible={true}*/}
            {/*                    keyBoardControl={true}*/}
            {/*                    pauseOnHover={true}*/}
            {/*                    responsive={UTILS.responsive}>{articles?.map((article, index) => {*/}
            {/*                    return (*/}
            {/*                        <Box key={index}>*/}
            {/*                            <Article article={article}/>*/}
            {/*                        </Box>*/}
            {/*                    )*/}
            {/*                })}*/}
            {/*                </Carousel>*/}
            {/*            )}*/}
            {/*        </Box>*/}

            {/*        <Stack direction="row" justifyContent="flex-end">*/}
            {/*            <Link to="/news/articles" style={{textDecoration: "none"}}>*/}
            {/*                <Button*/}
            {/*                    color="secondary"*/}
            {/*                    variant="text"*/}
            {/*                    endIcon={<KeyboardArrowRight color="secondary"/>}*/}
            {/*                    sx={{textTransform: "capitalize"}}>*/}
            {/*                    All Articles*/}
            {/*                </Button>*/}
            {/*            </Link>*/}
            {/*        </Stack>*/}
            {/*    </Container>*/}
            {/*</Box>*/}

        </Layout>
    )
}

export default HomePage;
