import Layout from "../../components/layout/layout";
import banner from "../../assets/images/banner.jpg";
import {
    Box,
    Container,
    Divider,
    Grid,
    LinearProgress,
    MenuItem,
    Pagination,
    Select,
    Stack,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import {selectArticles} from "../../redux/features/articles/articles-slice";
import {GridOn, ListOutlined} from "@mui/icons-material";
import Empty from "../../components/shared/empty";
import notFound from "../../assets/images/not-found.png";
import Article from "../../components/shared/article";

const ArticlesPage = () => {

    const {view} = useSelector(selectUI);
    const {articles, articleLoading, page, limit, count} = useSelector(selectArticles);

    const dispatch = useDispatch();

    return (
        <Layout>
            {articleLoading && <LinearProgress variant="query" color="secondary"/>}
            <Box
                sx={{
                    position: 'relative',
                    pt: 12.5,
                    minHeight: {xs: '50vh', lg: '100vh'},
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('${banner}')`,
                    backgroundAttachment: 'scroll',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingY: {xs: 4, lg: 0},
                    backgroundBlendMode: 'overlay',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}>
                <Container>
                    <Typography
                        align="center"
                        variant="h3"
                        sx={{color: 'white', mb: 4}}>
                        Articles
                    </Typography>
                    <Typography
                        align="center"
                        variant="h6" sx={{color: 'white', mb: 4}}>
                        Check out our latest articles
                    </Typography>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.paper"}}>
                <Container>
                    <Grid
                        container={true}
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item={true} xs="auto">
                            <Typography
                                variant="h4"
                                sx={{
                                    color: "secondary.main",
                                    textTransform: "uppercase"
                                }}>
                                Articles ({articles?.length})
                            </Typography>
                        </Grid>
                        <Grid item={true} xs="auto">
                            {view === 'grid' ?
                                (
                                    <ListOutlined
                                        color="secondary"
                                        sx={{
                                            padding: 0.8,
                                            fontSize: 36,
                                            cursor: 'pointer',
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 16,
                                            borderTopLeftRadius: 16,
                                            borderTopRightRadius: 16,
                                            backgroundColor: 'light.secondary'
                                        }}
                                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleView())}
                                    />
                                ) : (
                                    <GridOn
                                        color="secondary"
                                        sx={{
                                            padding: 0.8,
                                            fontSize: 36,
                                            cursor: 'pointer',
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 16,
                                            borderTopLeftRadius: 16,
                                            borderTopRightRadius: 16,
                                            backgroundColor: 'light.secondary'
                                        }}
                                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleView())}
                                    />)}
                        </Grid>
                    </Grid>

                    <Divider variant="fullWidth" sx={{my: 4}} light={true}/>

                    <Box>
                        {articles?.length === 0 ? (
                            <Box>
                                <Empty
                                    icon={<img style={{height: 200, width: 200}} src={notFound} alt=""/>}
                                    title="No articles available"
                                />
                            </Box>
                        ) : (
                            <Grid container={true} spacing={4}>
                                {articles?.map((article, index) => {
                                    return (
                                        <Grid item={true} xs={12} md={4} lg={3} key={index}>
                                            <Article article={article}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        )}
                    </Box>

                    <Divider variant="fullWidth" sx={{my: 4}} light={true}/>

                    <Grid
                        container={true}
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item={true} xs="auto">
                            <Pagination
                                color="secondary"
                                shape="circular"
                                size="large"
                                variant="outlined"
                                page={page}
                                count={parseInt(`${Math.ceil(count / limit)}`)}
                            />
                        </Grid>
                        <Grid item={true} xs="auto">
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Select
                                    variant="outlined"
                                    label="Page Size"
                                    value={limit}
                                    color="secondary"
                                    fullWidth={true}>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={40}>40</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Layout>
    )
}

export default ArticlesPage;
