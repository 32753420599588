import Layout from "../../components/layout/layout";
import banner from "../../assets/images/banner.jpg";
import thebeginning from "../../assets/images/thebeginning.jpg";
import growth from "../../assets/images/rsz_1img_7005_11zon.jpg";
import babyland from "../../assets/images/preschool.jpg";
import director from "../../assets/images/1. Sheila Acquah-Asare - School Director .jpg";
import goesInternational from "../../assets/images/ABOUT US PAGE-Mothercare goes International.jpg";
import {Box, CardMedia, Container, Grid, Stack, Typography} from "@mui/material";
import {MOTHERCARE_SCHOOL_DATA} from "../../utils/data";
import Management from "../../components/shared/management";
import Team from "../../components/shared/team";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import {UTILS} from "../../utils/utils";
import Mission from "../../components/shared/mission";
import mission from "./../../assets/images/mission.png";
import vision from "./../../assets/images/vision.png";
import CoreValue from "../../components/shared/core-value";
import Prefect from "../../components/shared/prefect";

const AboutUsPage = () => {

    return (
        <Layout>
            <Box
                sx={{
                    position: 'relative',
                    pt: 12.5,
                    minHeight: {xs: '50vh', lg: '100vh'},
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('${banner}')`,
                    backgroundAttachment: 'scroll',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingY: {xs: 4, lg: 0},
                    backgroundBlendMode: 'overlay',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}>
                <Container maxWidth="xl">
                    <Typography align="center" variant="h3" sx={{color: 'white', mb: 4}}>
                        About Us
                    </Typography>
                    <Typography align="center" variant="h6" sx={{color: 'white', mb: 4}}>
                        Welcome to Mothercare International School
                    </Typography>
                </Container>
            </Box>

            <Box sx={{minHeight: '30vh', alignItems: 'center', py: 8, backgroundColor: "background.paper"}}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                What the director says
                            </Typography>

                            <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                                At Mothercare School, we believe in providing high quality, outstanding value education
                                in a Christian and challenging learning environment. This is supported in our motto
                                (Proverbs 22 verse 6) which says “Train up a child in the way he should go: and when he
                                is old, he will not depart from it.”
                            </Typography>
                            <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                                We offer outstanding learning opportunities where our students attain results that are
                                regularly better than national standards. We are committed to continually reviewing our
                                progress in order to find ways to further improve. At Mothercare School, we recognize
                                that learning does not only happen in the classroom. For this reason, extracurricular
                                activities, including sports, drama, music and debating, are offered. Additional
                                opportunities for our students to enhance their learning include field trips,
                                conferences and international visits. This has resulted in our students being confident,
                                friendly, highly motivated, morally aware, culturally respectful and socially
                                responsible. We place a great importance on the ability of our students to be partners
                                in owning, leading and developing their learning.
                            </Typography>
                            <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                                We are committed to fostering excellent relationships with parents to ensure the very
                                best support. We also provide many opportunities for students to develop in all aspects
                                of their lives aimed at reaching their full potential as confident and valuable world
                                citizens and leaders.
                            </Typography>
                            <Typography variant="body1" sx={{color: 'text.primary', mb: 2}}>
                                Mothercare School is an excellent school with a great atmosphere for learning. We look
                                forward to welcoming you into our school community.
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.primary', fontWeight: 700}}>
                                - Ms. Sheila Acquah-Asare (Executive Director)
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={director}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 16,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 0,
                                }}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box sx={{minHeight: '30vh', alignItems: 'center', py: 8, backgroundColor: "background.default"}}>
                <Container maxWidth="xl">
                    <Typography
                        variant="h4"
                        sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                        Mission & Vision
                    </Typography>
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Mission
                                title="Mission"
                                description="To ensure that every child placed in our care has a solid, rewarding foundation for the future, through the use of a modern curriculum, good teaching practices, good Christian values and international relationships."
                                icon={<img src={mission} alt="" style={{height: 100, width: 100}}/>}/>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Mission
                                title="Vision"
                                description="To be Ghana’s first and best choice for educating leaders."
                                icon={<img src={vision} alt="" style={{height: 100, width: 100}}/>}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{minHeight: '30vh', alignItems: 'center', py: 8, backgroundColor: 'background.paper'}}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={thebeginning}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 16,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 0,
                                }}/>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                The Beginning
                            </Typography>

                            <Typography variant="body1" sx={{color: 'text.primary'}}>
                                The Mothercare Nursery School was founded in June 1965 with the objective of providing
                                childcare assistance to working mothers. It started with only eight pupils in a Military
                                married quarter at Juba Ridge, Burma Camp, Accra. The foundation stone for the nursery
                                building was laid by the late Brigadier General David Acquah-Asare, husband of the
                                founder, Mrs. Theodora Acquah-Asare. Upon completion of the building, Mr.
                                Owusu-Fordjuor, the then Commissioner for Education, commissioned the building on 8th
                                September, 1976 and a Board of Governors was inaugurated.
                            </Typography>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box sx={{minHeight: '30vh', alignItems: 'center', py: 8}}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                Growth
                            </Typography>

                            <Typography variant="body1" sx={{color: 'text.primary'}}>
                                Due to persistent pressure from parents to the proprietress to add a Primary department
                                to the Nursery and Kindergarten, construction of the Primary Department begun in October
                                1992 and by July of 1997 a stream of classes, one through six had been completed.
                                Unfortunately, August 1997 marked the passing of the founder, Mrs. Theodora
                                Acquah-Asare, and the entrance of her daughter, Ms. Sheila Acquah-Asare, as the director
                                of the school in January 1998. The school was then renamed and registered as Mothercare
                                School. In 1999, a 16-classroom block to house the Junior High department, a workshop,
                                computer laboratory and science laboratory were constructed. This expansion brought
                                about an increase in qualified well-trained teaching staff and a rising number of
                                students. Further development of the school environment, culture, and curriculum took
                                place with this increase.
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={growth}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 16,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 0,
                                }}/>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box sx={{minHeight: '30vh', alignItems: 'center', py: 8, backgroundColor: 'background.paper'}}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={goesInternational}
                                sx={{
                                    height: '100%', width: '100%',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 16,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 0,
                                }}/>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                Mothercare School Goes International
                            </Typography>

                            <Typography variant="body1" sx={{color: 'text.primary'}}>
                                By September 2009, a Secondary Department, which offered the British Cambridge
                                International General Certificate for Secondary Education (IGCSE), had begun on the same
                                premises. In 2015, the Secondary Department was moved to a separate location in East
                                Legon (and in 2020 to its current location, Tesano). The international secondary was
                                then named MCS International High. A further development of adding on the Senior High
                                School curriculum and a boarding facility to MCS International High is in the pipeline.
                            </Typography>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box sx={{minHeight: '30vh', alignItems: 'center', py: 8}}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                Babyland
                            </Typography>


                            <Typography variant="body1" sx={{color: 'text.primary'}}>
                                As a further step in its development, Mothercare School added on a Creche (dubbed
                                Babyland) in 2016 to care for children from the ages of 6 months till 2 years old.
                                According to UNICEF, up to 75% of each meal goes to build a baby's brain. Also, over 80%
                                of a baby’s brain is formed by the age of 3. It is therefore with hugs and kisses,
                                nutritious meals and games played with the babies in our care that Mothercare School
                                helps to build a baby’s brain. Also, to increase infants’ self-esteem and confidence, we
                                take a positive approach to a child’s learning process. Our babies/toddlers are engaged
                                in several activities, such as singing, listening to stories, question and answer
                                activities, playing with toys and so many more.
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={babyland}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 16,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 0
                                }}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.default"}}>
                <Container maxWidth="xl">
                    <Stack direction="column" spacing={2}>
                        <Typography
                            sx={{color: "text.primary", textTransform: "uppercase"}}
                            variant="h5"
                            align="center">
                            Our Core Values
                        </Typography>

                        <Box>
                            <Grid container={true} spacing={4}>
                                {MOTHERCARE_SCHOOL_DATA.VALUES.map((value, index) => {
                                    return (
                                        <Grid key={index} item={true} xs={12} md={6}>
                                            <CoreValue value={value}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </Stack>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: 'background.paper'}}>
                <Container maxWidth="xl">
                    <Box>
                        <Typography align="center" variant="h5" sx={{color: 'text.primary', mb: 4}}>
                            Board of Governors
                        </Typography>
                        <Carousel
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            infinite={true}
                            partialVisible={true}
                            keyBoardControl={true}
                            pauseOnHover={true}
                            responsive={UTILS.responsive}>{MOTHERCARE_SCHOOL_DATA.BOARD_OF_DIRECTORS.map((team, index) => {
                            return (
                                <Box key={index}>
                                    <Team team={team}/>
                                </Box>
                            )
                        })}
                        </Carousel>
                    </Box>
                </Container>
            </Box>

            <Box sx={{py: 8}}>
                <Container maxWidth="xl">
                    <Box>
                        <Typography align="center" variant="h5" sx={{color: 'text.primary', mb: 4}}>
                            Management & Senior Faculty
                        </Typography>

                        <Carousel
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            infinite={true}
                            partialVisible={true}
                            keyBoardControl={true}
                            pauseOnHover={true}
                            responsive={UTILS.responsive}>{MOTHERCARE_SCHOOL_DATA.MANAGEMENT.map((management, index) => {
                            return (
                                <Box key={index}>
                                    <Management management={management}/>
                                </Box>
                            )
                        })}
                        </Carousel>
                    </Box>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: 'background.paper'}}>
                <Container maxWidth="xl">
                    <Box>
                        <Typography align="center" variant="h5" sx={{color: 'text.primary', mb: 4}}>
                            Student Representative Council
                        </Typography>
                        <Carousel
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            infinite={true}
                            partialVisible={true}
                            keyBoardControl={true}
                            pauseOnHover={true}
                            responsive={UTILS.responsive}>{MOTHERCARE_SCHOOL_DATA.SRC.map((prefect, index) => {
                            return (
                                <Box key={index}>
                                    <Prefect prefect={prefect}/>
                                </Box>
                            )
                        })}
                        </Carousel>
                    </Box>
                </Container>
            </Box>

        </Layout>
    )
}

export default AboutUsPage;
