import {Box, Stack, Typography} from "@mui/material";

const Empty = ({title, icon}) => {
    return (
        <Box>
            <Stack direction="column" spacing={3} justifyContent="center" alignItems="center">
                {icon}
                <Typography variant="h5" sx={{color: 'text.primary'}} align="center">
                    {title}
                </Typography>
            </Stack>
        </Box>
    )
}

export default Empty;
