import Layout from "../../components/layout/layout";
import banner from "../../assets/images/banner.jpg";
import {Box, Button, CardMedia, Container, Grid, LinearProgress, Stack, Typography} from "@mui/material";
import Empty from "../../components/shared/empty";
import notFound from "../../assets/images/not-found.png";
import FacebookPost from "../../components/shared/facebook-post";
import {Link} from "react-router-dom";
import {KeyboardArrowRight} from "@mui/icons-material";
import TwitterFeed from "../../components/shared/twitter-feed";
import InstagramPost from "../../components/shared/instagram-post";
import Article from "../../components/shared/article";
import {useSelector} from "react-redux";
import {selectFacebook} from "../../redux/features/facebook/facebook-slice";
import {selectArticles} from "../../redux/features/articles/articles-slice";
import {selectTweets} from "../../redux/features/twitter/twitter-slice";
import {selectInstagram} from "../../redux/features/instagram/instagram-slice";
import graduates from "../../assets/images/BECE GRADUATES CONG.jpg";
import form from "../../assets/images/FORM 1.jpg";
import mentalHealth from "../../assets/images/MENTAL HEALTH.jpg";
import mentalHealth2022 from "../../assets/images/MENTAL HEALTH 2022.jpg";
import sixthForm from "../../assets/images/SIXTH FORM.jpg";
import socialMedia from "../../assets/images/SOCIAL MEDIA.jpg";
import openHouse from "../../assets/images/MCS Open House .jpg";
import form4 from "../../assets/images/FORM 4 ADMISSIONS.jpg";
import teachersDay from "../../assets/images/TEACHERS DAY.jpg";
import hiringIT from "../../assets/images/HIRING.jpg";
import hiringEnglish from "../../assets/images/HIRING ENGLISH.jpg";
import backToSchool from "../../assets/images/BACK TO SCHOOL.jpg";
import backToSchoolPrimary from "../../assets/images/BACK TO SCHOOL PRIMRY.jpg";
import openHouse1 from "../../assets/images/OPEN HOUSE.jpg";
import memorialDay from "../../assets/images/MEMORIAL DAY.jpg";

const NewsPage = () => {

    const {facebookLoading, facebookPosts} = useSelector(selectFacebook);
    const {articlesLoading, articles} = useSelector(selectArticles);
    const {twitterLoading, tweets} = useSelector(selectTweets);
    const {instagramLoading, instagramPosts} = useSelector(selectInstagram);

    return (
        <Layout>
            <Box
                sx={{
                    position: 'relative',
                    pt: 12.5,
                    minHeight: {xs: '50vh', lg: '100vh'},
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('${banner}')`,
                    backgroundAttachment: 'scroll',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingY: {xs: 4, lg: 0},
                    backgroundBlendMode: 'overlay',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}>
                <Container>
                    <Typography align="center" variant="h3" sx={{color: 'white', mb: 4}}>
                        Latest News
                    </Typography>
                    <Typography align="center" variant="h6" sx={{color: 'white', mb: 4}}>
                        Check us out on social media
                    </Typography>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.paper"}}>
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main", mb: 2}}>
                        Community News
                    </Typography>
                    <Typography variant="h6" align="center" sx={{color: "text.primary", mb: 4}}>
                        Admission Campaigns
                    </Typography>

                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={openHouse}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={sixthForm}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={graduates}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={form4}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={form}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={openHouse1}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.default"}}>
                <Container maxWidth="xl">
                    <Typography variant="h6" align="center" sx={{color: "text.primary", mb: 4}}>
                        Community Update
                    </Typography>

                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={memorialDay}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={mentalHealth}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={mentalHealth2022}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={socialMedia}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={teachersDay}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid> <Grid item={true} xs={12} md={4} lg={3}>
                        <CardMedia
                            component="img"
                            src={hiringIT}
                            sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                    </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={hiringEnglish}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={backToSchool}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={4} lg={3}>
                            <CardMedia
                                component="img"
                                src={backToSchoolPrimary}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.paper"}}>
                {facebookLoading && <LinearProgress variant="determinate" color="secondary"/>}
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main"}}>
                        Latest Facebook Posts
                    </Typography>

                    <Box sx={{py: 2}}>
                        {facebookPosts?.length === 0 ? (<Box>
                            <Empty
                                icon={<img style={{height: 200, width: 200}} src={notFound} alt=""/>}
                                title="No posts available"
                            />
                        </Box>) : (<Grid container={true} spacing={4}>
                            {facebookPosts?.map((post, index) => {
                                return (<Grid item={true} xs={12} md={4} lg={3} key={index}>
                                    <FacebookPost post={post}/>
                                </Grid>)
                            })}
                        </Grid>)}
                    </Box>

                    <Stack direction="row" justifyContent="flex-end">
                        <Link to="/news/facebook" style={{textDecoration: "none"}}>
                            <Button
                                color="secondary"
                                variant="text"
                                endIcon={<KeyboardArrowRight color="secondary"/>}
                                sx={{textTransform: "capitalize"}}>
                                All Facebook Posts
                            </Button>
                        </Link>
                    </Stack>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.default"}}>
                {twitterLoading && <LinearProgress variant="determinate" color="secondary"/>}
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main"}}>
                        Latest Tweets
                    </Typography>

                    <Box sx={{py: 2}}>
                        {tweets?.length === 0 ? (<Box>
                            <Empty
                                icon={<img style={{height: 200, width: 200}} src={notFound} alt=""/>}
                                title="No tweets available"
                            />
                        </Box>) : (<Grid container={true} spacing={4}>
                            {tweets?.map((tweet, index) => {
                                return (<Grid item={true} xs={12} md={4} lg={3} key={index}>
                                    <TwitterFeed feed={tweet}/>
                                </Grid>)
                            })}
                        </Grid>)}
                    </Box>

                    <Stack direction="row" justifyContent="flex-end">
                        <Link to="/news/twitter" style={{textDecoration: "none"}}>
                            <Button
                                color="secondary"
                                variant="text"
                                endIcon={<KeyboardArrowRight color="secondary"/>}
                                sx={{textTransform: "capitalize"}}>
                                All Tweets
                            </Button>
                        </Link>
                    </Stack>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.paper"}}>
                {instagramLoading && <LinearProgress variant="determinate" color="secondary"/>}
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main"}}>
                        Latest Instagram Posts
                    </Typography>

                    <Box sx={{py: 2}}>
                        {instagramPosts?.length === 0 ? (<Box>
                            <Empty
                                icon={<img style={{height: 200, width: 200}} src={notFound} alt=""/>}
                                title="No posts available"
                            />
                        </Box>) : (<Grid container={true} spacing={4}>
                            {instagramPosts?.map((post, index) => {
                                return (<Grid item={true} xs={12} md={4} lg={3} key={index}>
                                    <InstagramPost post={post}/>
                                </Grid>)
                            })}
                        </Grid>)}
                    </Box>

                    <Stack direction="row" justifyContent="flex-end">
                        <Link to="/news/instagram" style={{textDecoration: "none"}}>
                            <Button
                                color="secondary"
                                variant="text"
                                endIcon={<KeyboardArrowRight color="secondary"/>}
                                sx={{textTransform: "capitalize"}}>
                                All Posts
                            </Button>
                        </Link>
                    </Stack>
                </Container>
            </Box>

            <Box sx={{py: 8, backgroundColor: "background.default"}}>
                {articlesLoading && <LinearProgress variant="determinate" color="secondary"/>}
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main"}}>
                        Latest Articles
                    </Typography>

                    <Box sx={{py: 2}}>
                        {articles?.length === 0 ? (<Box>
                            <Empty
                                icon={<img style={{height: 200, width: 200}} src={notFound} alt=""/>}
                                title="No articles available"
                            />
                        </Box>) : (<Grid container={true} spacing={4}>
                            {articles?.map((article, index) => {
                                return (<Grid item={true} xs={12} md={4} lg={3} key={index}>
                                    <Article article={article}/>
                                </Grid>)
                            })}
                        </Grid>)}
                    </Box>

                    <Stack direction="row" justifyContent="flex-end">
                        <Link to="/news/articles" style={{textDecoration: "none"}}>
                            <Button
                                color="secondary"
                                variant="text"
                                endIcon={<KeyboardArrowRight color="secondary"/>}
                                sx={{textTransform: "capitalize"}}>
                                All Articles
                            </Button>
                        </Link>
                    </Stack>
                </Container>
            </Box>

        </Layout>)
}

export default NewsPage;
