import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";


const getPosts = createAsyncThunk("facebook/getPosts", async ({}) => {
    try {

    }catch (e) {

    }
});

const facebookSlice = createSlice({
    name: "facebook",
    initialState: {
        facebookLoading: false,
        facebookError: null,
        facebookPosts: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPosts.pending, (state) => {
            state.facebookLoading = true;
            state.facebookError = null;
        }).addCase(getPosts.fulfilled, (state, action) => {
            state.facebookPosts = action.payload;
            state.facebookLoading = false;
            state.facebookError = null;
        }).addCase(getPosts.rejected, (state, action) => {
            state.facebookPosts = [];
            state.facebookLoading = false;
            state.facebookError = action.payload;
        })
    }
});


const {reducer} = facebookSlice;

export const selectFacebook = state => state.facebook;

export default reducer;
