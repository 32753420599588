import {Card, CardContent, Stack, Typography} from "@mui/material";

const Feature = ({feature}) => {
    return (
        <Card elevation={0} sx={{height: '100%', backgroundColor: "light.secondary"}}>
            <CardContent>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" justifyContent="center">
                        <img src={feature.image} alt="" style={{height: 150, width: 150}}/>
                    </Stack>
                    <Typography align="center" variant="h6" sx={{color: "text.primary"}}>
                        {feature.name}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Feature;
