import {configureStore} from "@reduxjs/toolkit";
import uiReducer from "./../features/ui/ui-slice";
import messageReducer from "./../features/message/message-slice";
import instagramReducer from "./../features/instagram/instagram-slice";
import facebookReducer from "./../features/facebook/facebook-slice";
import twitterReducer from "./../features/twitter/twitter-slice";
import articleReducer from "./../features/articles/articles-slice";

import {CONSTANTS} from "../../utils/constants";

const theme = localStorage.getItem(CONSTANTS.MOTHERCARE_THEME_VARIANT) ?
    JSON.parse(localStorage.getItem(CONSTANTS.MOTHERCARE_THEME_VARIANT)) : 'dark';

const view = localStorage.getItem(CONSTANTS.MOTHERCARE_VIEW) ? localStorage.getItem(CONSTANTS.MOTHERCARE_VIEW): 'grid';
const store = configureStore({
    reducer: {
        ui: uiReducer,
        message: messageReducer,
        instagram: instagramReducer,
        facebook: facebookReducer,
        articles: articleReducer,
        twitter: twitterReducer
    },
    preloadedState: {
        ui: {theme, drawerOpen: false, view}
    },
    devTools: true
});

export default store;
