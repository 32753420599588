import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    Link as MUILink,
    Stack,
    Typography
} from "@mui/material";
import NavbarLink from "../shared/navbar-link";
import {useLocation} from "react-router-dom";
import {ChevronRightOutlined} from "@mui/icons-material";

const MobileFooter = () => {

    const {pathname} = useLocation();

    return (
        <Box sx={{backgroundColor: 'background.paper', py: 4}}>
            <Container>
                <Accordion elevation={0} variant="elevation">
                    <AccordionSummary expandIcon={<ChevronRightOutlined/>}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            Main
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2}>
                            <NavbarLink path="/" active={pathname === '/'} label="Home"/>
                            <NavbarLink path="/about" active={pathname === '/about'} label="About Us"/>
                            <NavbarLink path="/contact" active={pathname === '/contact'} label="Contact Us"/>
                            <NavbarLink path="/calendar" active={pathname === '/calendar'} label="Calendar"/>
                        </Stack>
                    </AccordionDetails>
                </Accordion>

                <Accordion elevation={0} variant="elevation">
                    <AccordionSummary expandIcon={<ChevronRightOutlined/>}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            Departments
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2}>
                            <NavbarLink
                                path="/departments"
                                active={pathname === '/departments'}
                                label="Overview"
                            />
                            <NavbarLink
                                path="/departments/preschool"
                                active={pathname === '/departments/preschool'}
                                label="Preschool"
                            />
                            <NavbarLink
                                path="/departments/primary"
                                active={pathname === '/departments/primary'}
                                label="Primary"
                            />
                            <NavbarLink
                                path="/departments/international"
                                active={pathname === '/departments/international'}
                                label="International Secondary"
                            />
                        </Stack>
                    </AccordionDetails>
                </Accordion>

                <Accordion elevation={0} variant="elevation">
                    <AccordionSummary expandIcon={<ChevronRightOutlined/>}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            News
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2}>
                            <NavbarLink
                                path="/departments"
                                active={pathname === '/news'}
                                label="Overview"
                            />
                            <NavbarLink
                                path="/news/preschool"
                                active={pathname === '/news/facebook'}
                                label="Facebook"
                            />
                            <NavbarLink
                                path="/news/instagram"
                                active={pathname === '/news/instagram'}
                                label="Instagram"
                            />
                            <NavbarLink
                                path="/news/twitter"
                                active={pathname === '/news/twitter'}
                                label="Twitter"
                            />
                            <NavbarLink
                                path="/news/articles"
                                active={pathname === '/news/articles'}
                                label="Articles"
                            />
                        </Stack>
                    </AccordionDetails>
                </Accordion>

                <Accordion elevation={0} variant="elevation">
                    <AccordionSummary expandIcon={<ChevronRightOutlined/>}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            Portals
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack diirection="column" spacing={3} >
                            <Stack spacing={2}>
                                <MUILink
                                    sx={{color: "text.secondary", fontWeight: 500}}
                                    href="https://student.mothercareschool.edu.gh"
                                    target="_blank"
                                    underline="hover">
                                    Student Portal
                                </MUILink>
                                <MUILink
                                    sx={{color: "text.secondary", fontWeight: 500}}
                                    href="https://parent.mothercareschool.edu.gh"
                                    target="_blank"
                                    underline="hover">
                                    Parent Portal
                                </MUILink>
                                <MUILink
                                    sx={{color: "text.secondary", fontWeight: 500}}
                                    href="https://staff.mothercareschool.edu.gh"
                                    target="_blank"
                                    underline="hover">
                                    Staff Portal
                                </MUILink>
                                <MUILink
                                    sx={{color: "text.secondary", fontWeight: 500}}
                                    href="https://alumni.mothercareschool.edu.gh"
                                    target="_blank"
                                    underline="hover">
                                    Alumni Portal
                                </MUILink>
                            </Stack>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </Container>
        </Box>
    )
}

export default MobileFooter;
