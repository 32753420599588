import Layout from "../../components/layout/layout";
import banner from "../../assets/images/internation-secondary-banner.jpg";
import image1 from "../../assets/images/international-secondary-image1.jpg";
import IMG_6492 from "../../assets/images/IMG_6492.jpg";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Link,
    Link as MUILink,
    Stack,
    Typography
} from "@mui/material";
import {MOTHERCARE_SCHOOL_DATA} from "../../utils/data";
import Feature from "../../components/shared/feature";
import Step from "../../components/shared/step";
import Benefit from "../../components/shared/benefit";
import SubjectText from "../../components/shared/subject-text";
import Activity from "../../components/shared/activity";
import {CallOutlined, LocationOnOutlined, MailOutlined, WhatsApp} from "@mui/icons-material";
import ImageGallery from "react-image-gallery";
import admissionForm from "../../assets/docs/MCEnrolmentForm2022.pdf";

const InternationalSecondaryPage = () => {

    return (
        <Layout>
            <Box
                sx={{
                    position: 'relative',
                    pt: 12.5,
                    minHeight: {xs: '50vh', lg: '100vh'},
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url('${banner}')`,
                    backgroundAttachment: 'scroll',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingY: {xs: 4, lg: 0},
                    backgroundBlendMode: 'multiply',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }}>
                <Container maxWidth="xl">
                    <Typography align="center" variant="h4" sx={{color: 'white', mb: 2}}>
                        Welcome to MCS High
                    </Typography>
                    <Typography align="center" variant="h3" sx={{color: 'white', mb: 2, fontWeight: 500}}>
                        International Secondary
                    </Typography>
                    <Stack direction="row" justifyContent="center">
                        <Typography
                            align="center"
                            variant="h5"
                            display="inline-block"
                            sx={{
                                color: 'secondary.main',
                                backgroundColor: "white",
                                padding: 2,
                                borderRadius: 2,
                                textTransform: "capitalize"
                            }}>
                            Your future starts here
                        </Typography>
                    </Stack>
                </Container>
            </Box>


            <Box sx={{py: 3, backgroundColor: "secondary.main"}}>
                <Container maxWidth="xl">
                    <Grid
                        container={true}
                        justifyContent="space-between"
                        spacing={2}
                        alignItems="center">
                        <Grid item={true} xs={12} md="auto">
                            <Typography gutterBottom={true} variant="h5" sx={{color: "white"}}>
                                Admissions open for 2023
                            </Typography>
                            <Typography gutterBottom={true} variant="body2" sx={{color: "white"}}>
                                Train up a child in the way he should go: and when he is old, he will not depart from it.
                            </Typography>
                            <Typography variant="body2" sx={{color: "white"}}>
                                Proverbs 22:6
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} md="auto">
                            <MUILink href="#enrol" sx={{textDecoration: 'none'}}>
                                <Button
                                    sx={{textTransform: 'capitalize'}}
                                    disableElevation={true}
                                    variant="contained"
                                    color="primary"
                                    size="large">
                                    Enroll Now
                                </Button>
                            </MUILink>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                Raising Ethical Global Leaders
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.primary', mb: 2}}>
                                The growth and success of Mothercare School can only be explained in the words of the
                                Methodist Hymn which says “Through all the changing scenes of life, In trouble and in
                                joy, The praises of my God shall still, My heart and tongue employ.”
                            </Typography>
                            <Typography variant="body2" sx={{color: 'text.primary'}}>
                                To the glory of God, in September 2009, we launched our International Secondary
                                Department called MCS International High, which offers the British Cambridge
                                International General Certificate for Secondary Education (IGCSE).
                            </Typography>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <CardMedia
                                component="img"
                                src={image1}
                                sx={{width: '100%', borderRadius: '5%'}}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.default'
                }}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={5}>
                            <CardMedia
                                component="img"
                                src={IMG_6492}
                                sx={{height: '100%', width: '100%', borderRadius: '5%'}}/>
                        </Grid>
                        <Grid item={true} xs={12} md={7}>
                            <Typography
                                variant="h5"
                                sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                                ABOUT OUR INTERNATIONAL SECONDARY (MCS INTERNATIONAL HIGH)
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.primary', mb: 2}}>
                                Set in an artistically designed campus, MCS International High is the secondary high
                                school of Mothercare School, with levels from Grade/Year 7 to Grade/Year 11, Advanced
                                Subsidiary (AS) Level and Advanced (A) Level. Outstanding teaching, a variety of
                                opportunities and good facilities make the school one of the best in the city. The
                                school equips its students (ages 11 to 18) with all the necessary skills required to
                                explore the opportunities of the present and face the challenges of the future with
                                confidence.
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.primary'}}>
                                Whilst academic success is of importance, there is also a firm belief that developing
                                social, sporting and artistic skills through both curricular and extra-curricular
                                activities shape a child’s future. MCS International High caters to the wide range of
                                abilities and talents of all the students by providing them opportunities to develop
                                their potential, enabling them to make a positive contribution to society. The record of
                                success in MCS International High rests not only on the efforts of students, but also on
                                the dedication of the staff.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: "background.paper"
                }}>
                <Container maxWidth="xl">
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                        Why Choose Us?
                    </Typography>
                    <Grid container={true} spacing={2}>
                        {MOTHERCARE_SCHOOL_DATA.BENEFITS.map((benefit, index) => {
                            return (
                                <Grid item={true} key={index} xs={12} md={4}>
                                    <Benefit benefit={benefit}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: "background.default"
                }}>
                <Container maxWidth="xl">
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                        Key Features
                    </Typography>
                    <Grid container={true} spacing={2}>
                        {MOTHERCARE_SCHOOL_DATA.INTERNATIONAL_KEY_FEATURES.map((feature, index) => {
                            return (
                                <Grid item={true} key={index} xs={12} md={4}>
                                    <Feature feature={feature}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>


            <Box
                id="steps"
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container maxWidth="xl">
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        Admission into MCS International High
                    </Typography>

                    <Typography
                        variant="body2"
                        sx={{color: 'text.primary', mb: 4}}>
                        MCS International High boasts of an excellent track record of high academic standards with
                        extremely good results in the IGCSE and A Levels. MCS International High can also boast of
                        students gaining scholarships into universities due to the exemplary results they obtained in
                        their examinations.
                    </Typography>

                    <Grid sx={{mb: 4}} container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                            <Step number="01">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    Download Application Form
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    Download{' '}
                                    <MUILink
                                        sx={{color: "secondary.main"}}
                                        href={admissionForm}
                                        download="MCEnrolmentForm2022.docx">
                                        Application Form
                                    </MUILink>{' '}
                                    / collect from the
                                    <MUILink
                                        sx={{color: "secondary.main"}}
                                        href={"https://goo.gl/maps/3FszttUjMoarMoiu8"}>
                                        school office
                                    </MUILink>.
                                </Typography>

                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="02">
                                <Typography variant="h6" sx={{color: "text.primary", mb: 1}}>
                                    Attend Readiness Assessment Interview
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 1}}>
                                    This includes an entrance examination (Maths, English and/or Science) and an
                                    interview
                                    with one of the head teachers.
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    (a) Note that there is a small fee covering the entrance examination.
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary", mb: 1}}>
                                    (b) Examinations may be taken on various dates between May and September on the
                                    school premises.
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    (c) Students will only be granted admission if there are vacancies in the class they
                                    require.
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="03">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    <span style={{fontWeight: "bold"}}>Submit Application Form</span> upon approval of
                                    Head teachers, along with the following:
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    1. Previous reports/Cumulative Records/Transcript
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    2. Student’s Birth Certificate/Passport/Vaccination Card
                                </Typography>
                                <Typography variant="body2" sx={{color: "text.secondary"}}>
                                    3. Two passport sized pictures
                                </Typography>
                            </Step>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Step number="04">
                                <Typography variant="h6" sx={{color: "text.primary"}}>
                                    <span style={{fontWeight: "bold"}}>Receive Offer of Admission</span> after full
                                    payment
                                    of fees has been made.
                                </Typography>
                            </Step>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.default'
                }}>
                <Container>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        Pre-IGCSE (Year 7 – 9), IGCSE, AS Level and A-Level subjects are chosen out of those listed
                        below:
                    </Typography>

                    <Grid sx={{mb: 4}} container={true} spacing={2}>
                        {MOTHERCARE_SCHOOL_DATA.PRE_IGCSE_SUBJECTS.map((subject, index) => {
                            return (
                                <Grid xs={12} md={6} lg={4} item={true} key={index}>
                                    <SubjectText
                                        iconColor="white"
                                        backgroundColor="secondary.main"
                                        color="white"
                                        subject={subject}/>
                                </Grid>
                            )
                        })}
                    </Grid>

                    <Typography
                        variant="body2"
                        sx={{
                            color: 'secondary.main',
                            fontWeight: 700,
                            textTransform: "uppercase",
                            fontStyle: "italic"
                        }}>
                        *Subjects may vary from term to term or may be selected for the IGCSE/AS Level/A Level.
                    </Typography>
                </Container>
            </Box>


            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container maxWidth="xl">
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        Pre-IGCSE (Year 7 – 9) - A selection of about 12 subjects are offered each term.
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        IGCSE (Year 10 – 11) - A student selection of any 8 (or more) of the above subjects are made
                        for the IGCSE examination.
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        AS/A Level (Year 12 – 13) - A student choice of any 3 (or more) subjects are chosen from the
                        list
                        above.
                    </Typography>

                </Container>
            </Box>

            <Box sx={{py: 8}}>
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: "secondary.main", mb: 4}}>
                        Gallery
                    </Typography>
                </Container>
                <ImageGallery
                    showPlayButton={false}
                    autoPlay={true}
                    slideDuration={300}
                    showThumbnails={false}
                    items={MOTHERCARE_SCHOOL_DATA.GALLERY.INTERNATIONAL_SECONDARY}
                />
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.default'
                }}>
                <Container maxWidth="xl">
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 2, textTransform: 'uppercase'}}>
                        A Level (Advanced Level) >> Lower 6 & Upper 6
                    </Typography>

                    <Grid sx={{mb: 4}} container={true} spacing={2}>
                        {MOTHERCARE_SCHOOL_DATA.A_LEVEL_SUBJECTS.map((subject, index) => {
                            return (
                                <Grid xs={12} md={6} lg={4} item={true} key={index}>
                                    <SubjectText
                                        iconColor="white"
                                        backgroundColor="secondary.main"
                                        color="white"
                                        subject={subject}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>

                    <Typography
                        variant="body2"
                        sx={{
                            color: 'secondary.main',
                            fontWeight: 700,
                            textTransform: "uppercase",
                            fontStyle: "italic"
                        }}>
                        AS English General Paper(Compulsory)
                    </Typography>
                </Container>
            </Box>


            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: 'background.paper'
                }}>
                <Container maxWidth="xl">
                    <Box>
                        <Typography
                            variant="h5"
                            sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                            Co-Curricular Activities
                        </Typography>

                        <Typography variant="body2" sx={{color: 'text.primary', mb: 2}}>
                            As part of our commitment to producing strong leaders, Mothercare School hosts a rich
                            co-curricular activity programme to increase a child's exposure to various interests,
                            careers and people. All activities within this programme are carefully designed to
                            contribute to a child’s leadership, socio-emotional and interpersonal development.
                        </Typography>

                        <Box>
                            <Typography
                                variant="h6"
                                sx={{color: 'secondary.main', mb: 4}}>
                                School Clubs and After-School Programs
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.primary', mb: 2}}>
                                Students may participate in a variety of activities including sports, creative arts,
                                media and writing, and stem-related activities.
                            </Typography>

                            <Grid sx={{mb: 4}} container={true} spacing={2}>
                                {["Swimming", "Debate Club", "Investment Club", "Drama", "Drama & Dance", "Drama", "Coding", "Literacy club", "Robotics Club"].map((example, index) => {
                                    return (
                                        <Grid xs={12} md={6} lg={4} key={index} item={true}>
                                            <Activity color="white" backgroundColor="secondary.main" label={example}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: "background.default"
                }}>
                <Container maxWidth="xl">
                    <Typography
                        variant="h5"
                        sx={{color: 'secondary.main', mb: 4, textTransform: 'uppercase'}}>
                        Facilities
                    </Typography>
                    <Grid container={true} spacing={2}>
                        {MOTHERCARE_SCHOOL_DATA.INTERNATIONAL_SCHOOL_KEY_FACILITIES.map((feature, index) => {
                            return (
                                <Grid item={true} key={index} xs={12} md={4}>
                                    <Feature feature={feature}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    minHeight: '30vh',
                    alignItems: 'center',
                    py: 8,
                    backgroundColor: "background.paper"
                }}>
                <Container maxWidth="xl">
                    <Typography variant="h4" align="center" sx={{color: 'text.primary', mb: 4}}>
                        Join our Family
                    </Typography>
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={3}>
                            <Link href="#" rel="noopener" underline="none">
                                <Card
                                    variant="outlined"
                                    sx={{
                                        borderBottomRightRadius: 16,
                                        borderTopRightRadius: 16,
                                        borderBottomLeftRadius: 16,
                                        borderTopLeftRadius: 0,
                                        height: '100%'
                                    }}>
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <LocationOnOutlined
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                Address
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                MSC International High Tesano, Accra Ghana
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>

                        <Grid item={true} xs={12} md={3}>
                            <Link href="mailto:info@mothercareschool.edu.gh" target="_blank" underline="none">
                                <Card
                                    sx={{
                                        borderBottomRightRadius: 16,
                                        borderTopRightRadius: 16,
                                        borderBottomLeftRadius: 16,
                                        borderTopLeftRadius: 0,
                                        height: '100%'
                                    }} variant="outlined">
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <MailOutlined
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                Email
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                info@mcshigh.com
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>

                        <Grid item={true} xs={12} md={3}>
                            <Link href="tel:+233302541429" target="_blank" underline="none">
                                <Card
                                    sx={{
                                        borderBottomRightRadius: 8,
                                        borderTopRightRadius: 34,
                                        borderBottomLeftRadius: 34,
                                        borderTopLeftRadius: 8,
                                        height: '100%'
                                    }} variant="outlined">
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <CallOutlined
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                Phone
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                +233 (0)302 541 429
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>

                        <Grid item={true} xs={12} md={3}>
                            <Link href="https://api.whatsapp.com/send?phone=233208564485" target="_blank"
                                  underline="none">
                                <Card
                                    sx={{
                                        borderBottomRightRadius: 8,
                                        borderTopRightRadius: 34,
                                        borderBottomLeftRadius: 34,
                                        borderTopLeftRadius: 8,
                                        height: '100%'
                                    }} variant="outlined">
                                    <CardContent>
                                        <Stack direction="column" spacing={3}>
                                            <Stack direction="row" justifyContent="center">
                                                <WhatsApp
                                                    color="secondary"
                                                    sx={{
                                                        backgroundColor: 'light.secondary',
                                                        padding: 1,
                                                        fontSize: 36,
                                                        borderBottomRightRadius: 4,
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderTopLeftRadius: 4
                                                    }}/>
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                sx={{color: 'text.primary'}}>
                                                WhatsApp
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{color: 'text.secondary'}}>
                                                +233 (0)208 564 485
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Layout>
    )
}

export default InternationalSecondaryPage;
