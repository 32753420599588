import Layout from "../../components/layout/layout";

const AlumniPage = () => {
    return (
        <Layout>

        </Layout>
    )
}

export default AlumniPage;
